import { useState } from 'react';

const AddBankInfoValidation = () => {
    const [errors, setErrors] = useState({});

    const validateForm = (paymentModeId, inputValues, bankDocument) => {

        let errors = {};
        let formIsValid = true;

        for (const fieldName in inputValues) {
            if (inputValues.hasOwnProperty(fieldName)) {
                const { fieldValue } = inputValues[fieldName];

                if (!fieldValue.trim()) {
                    formIsValid = false;
                    errors[fieldName] = `Enter ${fieldName}`;
                } else {
                    switch (fieldName) {
                        case 'Bank Name':
                            if (!/^[a-zA-Z\s]+$/.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = 'Only alphabetic keywords allowed !';
                            }
                            break;
                        case 'Account Number':
                            if (fieldValue.length < 8 || fieldValue.length > 20) {
                                formIsValid = false;
                                errors[fieldName] = 'Account number should have 8-20 digits';
                            }
                            break;
                        case 'IFSC Code':
                            if (!/^[A-Za-z]{4}\d{7}$/.test(fieldValue)) {
                                formIsValid = false;
                                errors[fieldName] = 'Invalid IFSC Code format';
                            }
                            break;
                        default:
                            break;
                    }
                }
            }

        }
        if (!paymentModeId) {
            formIsValid = false;
            errors.paymentModeId = "Select Payment Mode";
        }
        if (bankDocument.length === 0) {
            formIsValid = false;
            errors.bankDocument = "Please upload a document !";
        }
        else {
            const areAllImages = bankDocument.every((file) =>
                file.type.startsWith('image/')
            );
            if (!areAllImages) {
                formIsValid = false;
                errors.bankDocument = "Only PNG,JPEG,JPG files are allowed !";
            }
        }
        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default AddBankInfoValidation;
