import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import BackButton from "../../../assets/image/BackButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VENDOR, SITE_CONFIGERATION, VENDOR_TERMS_SUBSIDIARY, VENDOR_ITEMS, ADD_CONTRACT } from "../../../utils/apiRoutes";
import { getApi, postApi } from "../../../utils/services";
import { VENDOR_APPROVE, CONTRACT_SITE_CONFIGERATION_ID } from "../../../utils/constant";
import AddContractValidation from '../../../middleware/addContractValidation';
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import moment from "moment";

const Contractpage = () => {

    const navigate = useNavigate();
    const transactionList = useTransactionContext();
    const user = JSON.parse(localStorage.getItem("userData"));
    const [vendorList, setVendorList] = useState([]);
    const [contractStartDate, setContractStartDate] = useState('');
    const [terms, setTerms] = useState('');
    const [subsidiary, setSubsidiary] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [vendorItems, setVendorItems] = useState([]);
    const [contractTotal, setContractTotal] = useState(0);
    const [vendorName, setVendorName] = useState('');
    const [attachDocument, setAttachDocument] = useState(false)
    const [loader, setLoader] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const { errors, setErrors, validateForm } = AddContractValidation();

    const [formState, setFormState] = useState({
        vendorId: '',
        startDate: '',
        endDate: '',
        itemDescription: [{}],
        contractDocument: [],
    })

    const handleList = async () => {
        try {

            let vendorResult = await getApi(VENDOR);
            vendorResult = vendorResult.res.vendor_list.filter(i => i.Status === VENDOR_APPROVE && i.bankInfoFlag === 1);
            setVendorList(vendorResult)

            let startDateResult = await getApi(SITE_CONFIGERATION);
            startDateResult = startDateResult.res.siteConfigeration_list.find(i => i.id === CONTRACT_SITE_CONFIGERATION_ID);
            setContractStartDate(startDateResult.Days)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleList()
    }, [])

    const fetchData = async () => {
        try {
            if (formState.vendorId) {

                const result = await getApi(`${VENDOR_TERMS_SUBSIDIARY}/${formState.vendorId}`);
                setTerms(result.res.terms_subsidiary.Term)
                setSubsidiary(result.res.terms_subsidiary.Name)
                setCurrencySymbol(result.res.terms_subsidiary.Symbol)

                const vendorItemsResult = await getApi(`${VENDOR_ITEMS}/${formState.vendorId}`);
                setVendorItems(vendorItemsResult.res.itemsList)
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
    }, [formState.vendorId])

    useEffect(() => {
        const calculateFinalTotalAmount = () => {
            const contractTotal = formState.itemDescription.reduce(
                (acc, item) => acc + parseFloat(item.totalAmount || 0),
                0
            );
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.contractTotal;
                return updatedErrors;
            });
            setContractTotal(contractTotal.toFixed(2));
        };
        calculateFinalTotalAmount();
    }, [formState.itemDescription]);

    const minStartDate = moment().subtract(contractStartDate, 'days');

    const formattedEndDate = formState.startDate
        ? new Date(formState.startDate)
        : new Date();
    formattedEndDate.setDate(formattedEndDate.getDate() + 1);

    const minEndDate = formattedEndDate.toISOString().split("T")[0];

    const handleChange = (e) => {
        const { name, value } = e.target

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
        });

        setFormState({
            ...formState,
            [name]: value,
        })

        if (name === 'vendorId') {
            if (value) {
                let vendorData = vendorList.find((i) => i.id == value);
                const words = vendorData.LegalName.split(" ");
                const vendorName = words[0].trim();
                const vendorNameLowerCase = vendorName.toLowerCase();
                setVendorName(vendorNameLowerCase);
            }
        }
    };

    const handleItemDescriptionChange = (e, index) => {
        const { name, value } = e.target;

        let newValue;
        if (name === "rate") {
            newValue = value.replace(/[^0-9.]/g, "");

            const dotindex = newValue.indexOf(".");

            if (dotindex !== -1 && newValue.length - dotindex > 3) {
                newValue = newValue.slice(0, dotindex + 3);
            }
        }
        else if (name === "quantity") {
            newValue = value.replace(/[^0-9]/g, "");
        } else {
            newValue = value;
        }

        const updatedItemDescription = [...formState.itemDescription];
        updatedItemDescription[index] = {
            ...updatedItemDescription[index],
            [name]: newValue,
        };

        const rate = parseFloat(updatedItemDescription[index].rate);
        const quantity = parseFloat(updatedItemDescription[index].quantity);
        const totalAmount =
            isNaN(rate) || isNaN(quantity)
                ? ""
                : (rate * quantity).toFixed(2);

        updatedItemDescription[index].totalAmount = totalAmount;

        setFormState((prevState) => ({
            ...prevState,
            itemDescription: updatedItemDescription,
        }));

        const updatedErrors = { ...errors };
        if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
            delete updatedErrors.itemDescription[index][name];
        }

        setErrors(updatedErrors);
    };

    const handleAdd = () => {
        const newItem = {
            itemId: "",
            rate: "",
            quantity: "",
            totalAmount: "",
        };
        setFormState((prevState) => ({
            ...prevState,
            itemDescription: [...prevState.itemDescription, newItem],
        }));
    };

    const handleRemove = (index, event) => {
        event.preventDefault();

        if (formState.itemDescription.length === 1) {
            alert("Atleast one item should be there");
            return;
        }

        const updatedItemDescription = [...formState.itemDescription];
        updatedItemDescription.splice(index, 1);

        const updatedErrors = { ...errors };
        if (updatedErrors.itemDescription && updatedErrors.itemDescription.length > index) {
            updatedErrors.itemDescription.splice(index, 1);
        }

        setFormState((prevState) => ({
            ...prevState,
            itemDescription: updatedItemDescription,
        }));

        setErrors(updatedErrors);
    };

    const handleFileChange = (e) => {
        const contractDocument = e.target.files;

        const updatedErrors = { ...errors };
        delete updatedErrors.files;

        if (contractDocument) {
            // for (const file of files) {
            //     if (!isValidFileType(file)) {
            //         updatedErrors.files = "Only PDF file is allowed !";
            //         break;
            //     }
            //     if (file.size > MAX_FILE_SIZE) {
            //         updatedErrors.files = "Maximum allowed file size is 2MB";
            //         break;
            //     }
            //     if (files.length > 5) {
            //         updatedErrors.files = "Only 5 files are allowed";
            //         break;
            //     }
            // }
        }

        setFormState((prevState) => ({
            ...prevState,
            contractDocument: Array.from(contractDocument),
        }));

        setErrors(updatedErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm(formState.vendorId, formState.startDate, formState.endDate, formState.itemDescription, contractTotal, formState.contractDocument);

        if (isValid) {

            setLoader(true);
            setSubmitDisable(true);

            let contractDocumentArray = [];

            const updatedItemDescription = formState.itemDescription.map((row) => ({
                itemId: Number(row.itemId),
                rate: Number(row.rate),
                quantity: Number(row.quantity),
                totalAmount: Number(row.totalAmount)
            }))

            const fileUploadPromises = formState.contractDocument.map(async (file) => {
                const result = await uploadFile(
                    "contracts",
                    vendorName,
                    formState.vendorId,
                    file
                );
                const singlefile = {
                    FileName: result.fileName,
                    FilePath: result.filePath,
                };
                contractDocumentArray.push(singlefile);
            });

            await Promise.all(fileUploadPromises);

            const result = await postApi(ADD_CONTRACT, {
                "vendorId": Number(formState.vendorId),
                "transactionId": transactionList[2].id,
                "roleId": user.RoleId,
                "startDate": formState.startDate,
                "endDate": formState.endDate,
                "itemDescription": updatedItemDescription,
                "contractTotalAmount": Number(contractTotal),
                "contractDocument": contractDocumentArray
            });

            if (result.res.success) {
                Swal.fire({
                    position: "center-center",
                    icon: "success",
                    title: result.res.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
                navigate("/contract-listing");
            }

        };
    };

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Purchase Contract</title>
            </Helmet>
            <div className="container-section">

                <div className="section-header">
                    <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
                        <BackButton />
                    </button>
                    <h3>Purchase Contract</h3>
                </div>

                <form>

                    <div className="form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="vendor" className="form-label">
                                    Vendor<span className="required">*</span>
                                </label>

                                <select id="vendorId" className="form-select" name="vendorId" value={formState.vendorId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    {vendorList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.LegalName}
                                        </option>
                                    ))}
                                </select>
                                {errors.vendorId && <span className="cstm-err">{errors.vendorId}</span>}
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="startDate" className="form-label">
                                    Start Date<span className="required">*</span>
                                </label>

                                <input type="date" id="startDate" className="form-control" name="startDate" value={formState.startDate}
                                    onKeyDown={(e) => e.preventDefault()}
                                    min={minStartDate.format('YYYY-MM-DD')}
                                    onChange={handleChange}
                                />
                                {errors.startDate && <span className="cstm-err">{errors.startDate}</span>}
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="endDate" className="form-label">
                                    End Date<span className="required">*</span>
                                </label>

                                <input
                                    type="date"
                                    id="endDate"
                                    className="form-control"
                                    name="endDate"
                                    value={formState.endDate}
                                    min={minEndDate}
                                    disabled={!formState.startDate}
                                    onChange={handleChange}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                                {errors.endDate && <span className="cstm-err">{errors.endDate}</span>}
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                                <label htmlFor="terms" className="form-label">
                                    Terms
                                </label>
                                <input
                                    type="text"
                                    id="terms"
                                    className="form-control"
                                    name="terms"
                                    value={formState.vendorId ? terms : ''}
                                    disabled
                                ></input>
                            </div>

                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                                <label htmlFor="subsidiary" className="form-label">
                                    Subsidiary
                                </label>
                                <input
                                    type="text"
                                    id="subsidiary"
                                    className="form-control"
                                    name="subsidiary"
                                    value={formState.vendorId ? subsidiary : ''}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-style">
                        <div className="">
                            <div className="section-sub-heading">
                                <h3>Item Description</h3>
                            </div>
                            <div className="pur-item-list table-responsive tbl-body-shadow">
                                <table className="w-100 table table-theme-1 mb-0 ">
                                    <thead className="table-heads">
                                        <tr>
                                            <th className="text-center">Item</th>
                                            <th className="text-center">Rate</th>
                                            <th className="text-center">Quantity</th>
                                            <th className="text-center">Amount</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {formState.itemDescription.map((item, index) => (
                                            <tr key={index}>
                                                <td className="custom-width">
                                                    <div className="position-relative">
                                                        <select className="form-select mb-20" name="itemId"
                                                            value={item.itemId}
                                                            onChange={(e) => handleItemDescriptionChange(e, index)}
                                                        >
                                                            <option value="">Select</option>
                                                            {formState.vendorId && vendorItems.map((item, index) => (
                                                                <option key={index} value={item.itemId}>
                                                                    {item.itemname}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.itemDescription &&
                                                            errors.itemDescription[index] &&
                                                            errors.itemDescription[index].itemId && (
                                                                <span className="cstm-err">
                                                                    {errors.itemDescription[index].itemId}
                                                                </span>
                                                            )}
                                                    </div>
                                                </td>
                                                <td className="custom-width">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control text-end mb-20" name="rate"
                                                            autocomplete="off"
                                                            placeholder="Rate"
                                                            value={item.rate}
                                                            onChange={(e) => handleItemDescriptionChange(e, index)}
                                                        >
                                                        </input>
                                                        {errors.itemDescription &&
                                                            errors.itemDescription[index] &&
                                                            errors.itemDescription[index].rate && (
                                                                <span className="cstm-err">
                                                                    {errors.itemDescription[index].rate}
                                                                </span>
                                                            )}
                                                    </div>
                                                </td>
                                                <td className="custom-width">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control text-end mb-20" name="quantity"
                                                            autocomplete="off"
                                                            placeholder="Quantity"
                                                            value={item.quantity}
                                                            onChange={(e) => handleItemDescriptionChange(e, index)}
                                                        >
                                                        </input>
                                                        {errors.itemDescription &&
                                                            errors.itemDescription[index] &&
                                                            errors.itemDescription[index].quantity && (
                                                                <span className="cstm-err">
                                                                    {errors.itemDescription[index].quantity}
                                                                </span>
                                                            )}
                                                    </div>
                                                </td>
                                                <td className="custom-width">
                                                    <div className="position-relative">
                                                        <input type="text" className="form-control text-end mb-20" name="totalAmount"
                                                            autoComplete="off"
                                                            value={item.totalAmount}
                                                            disabled
                                                        >
                                                        </input>
                                                    </div>
                                                </td>
                                                <td className="custom-width">
                                                    <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip
                                                                    className="custom-tooltip"
                                                                    id="tooltips1"
                                                                >
                                                                    Delete
                                                                </Tooltip>
                                                            }
                                                        ><span
                                                            className=""
                                                            onClick={(e) => handleRemove(index, e)}
                                                        >
                                                                <DeleteIcon />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="Addmoreicon">
                            <button type="button" className="addIcon" onClick={handleAdd}>
                                <p>+</p>
                            </button>
                        </div>

                        <div className="row justify-content-end mb-20">
                            <div className="col-md-3 position-relative">
                                <label htmlFor="contractTotal" className="form-label">
                                    Total Amount
                                </label>

                                <input
                                    type="text"
                                    id="contractTotal"
                                    className="form-control text-end"
                                    name="contractTotal"
                                    value={`${currencySymbol} ${contractTotal}`}
                                    disabled
                                />
                                {errors.contractTotal && <span className="cstm-err">{errors.contractTotal}</span>}
                            </div>
                        </div>

                        <div className="cstm-checkbox mb-2">
                            <div className="cstm-checkbox-inner d-flex align-items-center">
                                <input className="form-check-input m-0" type="checkbox" checked={attachDocument}
                                    onChange={(e) => setAttachDocument(e.target.checked)} />
                                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                                    <label htmlFor="" className="ms-2">
                                        Check for attach documents
                                    </label>
                                    <span className="required">*</span>
                                    <span className=" infoIcon">{/* <InfoIcon /> */}</span>
                                </div>
                            </div>
                        </div>
                        <div className=" row mb-3">
                            <div className="col-xl-3 col-lg-4 col-md-5 mb-20 position-relative">
                                <div className="input-group mmm">
                                    <input
                                        type="file"
                                        id="contractDocument"
                                        className="form-control"
                                        name="contractDocument"
                                        onChange={handleFileChange}
                                        multiple
                                        disabled={!attachDocument}
                                    />
                                    {errors.files && <span className="cstm-err">{errors.files}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mb-20">
                            <div className="cstm-success-btn ms-2">
                                <button type="submit" className="btn" onClick={(e) => handleSubmit(e)} disabled={submitDisable}>
                                    {loader ?
                                        <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </>
                                        :
                                        "Submit"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </form>

            </div >
        </>

    );
};

export default Contractpage;
