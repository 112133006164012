import React, { useEffect, useState } from "react";
import { CONTRACT_LIST } from "../../../utils/apiRoutes";
import { getApi } from "../../../utils/services";
import { useFeedsColumnContracts } from "../../../utils/helpers/tableHelpers/useFeedColumnContracts";
import { VENDORID } from "../../../utils/constant";
import SpinnerIcon from "../../../assets/image/SpinnerIcon";
import Contract from "../../Table/Contract";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";

const PurchaseContactList = () => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const [purchaseContract, setPurchaseContarct] = useState([]);
  const [loader, setLoader] = useState(false);

  const handlerPurchaseContractList = async () => {
    try {
      let routeName
      if (user.RoleId === VENDORID) {
        routeName = `${CONTRACT_LIST}?vendorId=${user.id}`
      }
      else {
        routeName = CONTRACT_LIST
      }
      setLoader(true)
      let result = await getApi(routeName);
      result = result.res.contract_list;
      setLoader(false)
      setPurchaseContarct(result)
      
    } catch (error) {
      console.log(error);
    }
  }

  const purchasecolumns = useFeedsColumnContracts(handlerPurchaseContractList)

  useEffect(() => {
    handlerPurchaseContractList()
  }, [])

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Contract List</title>
      </Helmet>

        <Loader isLoading={loader}/>
       
        <div className="container-section">
          <div className="section-header">
            <h3>Purchase Contracts</h3>
          </div>
          <div className="form-style">
            <div className="">
              <Contract columns={purchasecolumns} data={purchaseContract} roleId={user.RoleId} />
            </div>
          </div>
        </div>

     
    </>
  );
};

export default PurchaseContactList;