import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../../utils/Context";
import { uploadFile } from "../../../../utils/helpers/useFileUpload";
import { BANK_DETAILS_LIST, EDIT_BANK_DETAILS, EDIT_VENDOR, PAYMENT_MODE_LIST } from "../../../../utils/apiRoutes";
import { BASE_IMAGE_URL, getApi, postApi } from "../../../../utils/services";
import EditBankInfoValidation from "../../../../middleware/editBankInfoValidation";
import FilePreviewIcon from "../../../../assets/image/FilePreviewIcon";
import Modal from "react-bootstrap/Modal";
import cross from "../../../../assets/image/cross.png";
import { useTransactionContext } from "../../../../utils/helpers/useTransaction";

const EditFinanceInfo = ({ activeStep, steps, setActiveStep, viewVendorData }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const transactionList = useTransactionContext();

    const [bankDocument, setBankDocument] = useState([]);
    const [bankDetailsList, setBankDetailsList] = useState([])
    const [bankDocumentObject, setBankDocumentObject] = useState({ BankDocumentName: viewVendorData.CancelChequeName, BankDocumentPath: viewVendorData.CancelChequePath })
    const [submitDisable, setSubmitDisable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [paymentModeList, setPaymentModeList] = useState([])
    const [inputValues, setInputValues] = useState({})
    const [paymentModeId, setPaymentModeId] = useState(viewVendorData.PaymentModeId)
    const { errors, setErrors, validateForm } = EditBankInfoValidation();

    const [showBankDetails, setShowBankDetails] = useState(false);

    useEffect(() => {
        const handleViewPaymentMode = async () => {
            let result = await getApi(`${PAYMENT_MODE_LIST}?subsidiaryId=${viewVendorData.SubsidiaryId}`)
            setPaymentModeList(result.res.paymentMode_list)
        }
        handleViewPaymentMode()
    }, [viewVendorData])

    const handleBankDetails = async () => {
        if (paymentModeId) {
            let result = await getApi(`${BANK_DETAILS_LIST}/${paymentModeId}`)
            setBankDetailsList(result.res.bank_details_list)
            setShowBankDetails(true);

            const updatedBankDetails = mergeArray(result.res.bank_details_list, viewVendorData.bankInfo)

            if (updatedBankDetails.length > 0) {
                const initialValues = updatedBankDetails.reduce((acc, curr) => {
                    acc[curr.FieldName] = { fieldId: curr.id, fieldValue: curr.FieldValue };
                    return acc;
                }, {});
                setInputValues(initialValues)
            }

            else {
                const initialValues = result.res.bank_details_list.reduce((acc, curr) => {
                    acc[curr.FieldName] = { fieldId: curr.id, fieldValue: '' };
                    return acc;
                }, {});
                setInputValues(initialValues)
            }

        }

    }

    const mergeArray = (array1, array2) => {

        let mergedArray = [];

        array1.forEach(itemA => {

            let matchingItem = array2.find(itemB => itemB.VendorBankConfigId === itemA.id);
            if (matchingItem) {
                mergedArray.push({ id: itemA.id, FieldValue: matchingItem.FieldValue, FieldName: itemA.FieldName });
            }
        });

        return mergedArray;
    }

    useEffect(() => {
        handleBankDetails()
    }, [paymentModeId])

    const handlePayMode = (e) => {
        setPaymentModeId(Number(e.target.value))
        if (errors.paymentModeId) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                paymentModeId: "",
            }));
        }
        setShowBankDetails(false);
        setErrors({});
    }

    const handleInputChange = (fieldName, id, value) => {

        if (fieldName === 'Account Number') {
            const newVal = value.replace(/[^0-9]/g, '');

            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: newVal },
            }));
        }

        else {
            setInputValues((prevValues) => ({
                ...prevValues,
                [fieldName]: { fieldId: id, fieldValue: value },
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: '',
        }));
    }

    const handleFileChange = (e) => {
        const newfile = e.target.files;
        setBankDocument(Array.from(newfile));

        const updatedErrors = { ...errors };
        delete updatedErrors.bankDocument;

        setErrors(updatedErrors);

    };

    const handleCrossImage = () => {
        setBankDocumentObject()
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm(paymentModeId, inputValues, bankDocumentObject, bankDocument);

        if (isValid) {

            setLoader(true);
            setSubmitDisable(true);

            let bankFile = {}
            if (bankDocument.length <= 0) {
                bankFile = { BankDocumentName: null, BankDocumentPath: null }
            }

            const filePromises = bankDocument.map(async (file) => {
                if (!!file) {
                    const result = await uploadFile("document", 'finance', 'vendor', file);
                    bankFile = { BankDocumentName: result.fileName, BankDocumentPath: result.filePath }
                }
            });

            await Promise.all(filePromises);

            const vendorBankInfoIdArray = viewVendorData.bankInfo.map(item => item.VendorBankInfoId);

            const updatedValues = Object.entries(inputValues).reduce((acc, [key, value]) => {
                const newKey = key.replace(/\s+/g, '');
                acc[newKey] = value;
                return acc;
            }, {});

            const result = await postApi(EDIT_BANK_DETAILS, {
                "vendorId": user.id,
                "transactionId": transactionList[3].id,
                "roleId": user.RoleId,
                "bankDocument": bankDocumentObject ? bankDocumentObject : bankFile,
                "paymentModeId": paymentModeId,
                "bankDetails": updatedValues,
                "vendorBankInfoIds": vendorBankInfoIdArray
            })

            if (result.res.success) {
                setActiveStep(activeStep + 1)
            }
        };
    }

    return (
        <>
            <div className=" m-0 mb-5 card-edit-vendor-div mt-4">
                <div className="section-header">
                    <h3>Financial Information</h3>
                </div>
                <div className="form-style">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                            <label htmlFor="paymentMode" className="form-label">Payment Mode<span className="required">*</span></label>
                            <select name="paymentMode" id="paymentMode" className="form-select" value={paymentModeId} onChange={handlePayMode} >
                                <option value="">Select</option>
                                {
                                    paymentModeList?.map((item, index) => (
                                        <option key={index} value={item?.id}>{item?.Name}</option>))
                                }
                            </select>
                            {errors.paymentModeId && <span className="cstm-err">{errors.paymentModeId}</span>}
                        </div>

                        {showBankDetails && bankDetailsList.map((row, index) => (
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative" key={index}>
                                <label htmlFor="" className="form-label">{row.FieldName}<span className="required">*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter ${row.FieldName}`}
                                    value={inputValues[row.FieldName]?.fieldValue || ''}
                                    onChange={(e) => handleInputChange(row.FieldName, row.id, e.target.value)}
                                />
                                {errors[row.FieldName] && (
                                    <span className="cstm-err">{errors[row.FieldName]}</span>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        {showBankDetails && (
                            <div className="col-xl-6 col-lg-6 col-md-6  ">
                                <label htmlFor="fileInput" className="form-label">Upload Document<span className="required">*</span></label>
                                <div className="upload-documents-fields">
                                    {bankDocumentObject ?
                                        <>
                                            <div className="file-view-button">
                                                <span className="view-file-cross-icon">
                                                    <span
                                                        className="mdi mdi-close-circle"
                                                        onClick={handleCrossImage}
                                                    ></span>
                                                    <button className="btn " onClick={handleShow}>
                                                        <span className="d-flex align-items-center ">
                                                            <FilePreviewIcon /> <span>Preview</span>
                                                        </span>
                                                    </button>
                                                </span>

                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="d-flex align-items-center position-relative w-100 fin-info-upl-field">
                                                <div className="input-group  ">
                                                    <input
                                                        type="file"
                                                        id="files"
                                                        className="form-control input-fields-cstm"
                                                        onChange={handleFileChange}
                                                        multiple
                                                    />
                                                    {errors.bankDocument && <span className="cstm-err">{errors.bankDocument}</span>}
                                                </div>
                                                <div className="file-view-button ps-2">
                                                    <span className="view-file-cross-icon">
                                                        <button className="btn" onClick={handleShow}>
                                                            <span className="d-flex align-items-center">
                                                                <FilePreviewIcon /><span>Preview</span>
                                                            </span>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="stepper-next-btn">
                {activeStep !== 0 && activeStep !== steps.length - 1 && (
                    <span className="cstm-dngr-btn me-2">
                        <button
                            onClick={() => setActiveStep(activeStep - 1)}
                            className="btn  previousstepper "
                        >
                            Previous
                        </button>
                    </span>
                )}
                <div className="cstm-success-btn">
                    <button
                        onClick={(e) => handleSubmit(e)}
                        className="btn"
                        disabled={submitDisable}
                    >
                        {loader ?
                            <>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </>
                            :
                            "Submit"
                        }
                    </button>
                </div>
            </div>

            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    animation={false}
                    className="modal-wrap custom-modal-backdrop"
                    backdrop="static"
                    keyboard={false}
                >
                    <div className="file-preview-modal-body">
                        <div className="">
                            <span className="cross" onClick={handleClose}>
                                <img src={cross} alt="" />
                            </span>
                            <div className="">
                                <>
                                    {typeof bankDocumentObject?.BankDocumentPath === 'string' ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="view-logo">
                                                <img src={`${BASE_IMAGE_URL}/${bankDocumentObject?.BankDocumentPath} `} alt="" />
                                            </div>
                                        </div>
                                    ) : Array.isArray(bankDocument) && bankDocument.length > 0 ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="view-logo">
                                                <img
                                                    src={URL.createObjectURL(new Blob([bankDocument[0]]))}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-center">
                                            <div className="view-logo">
                                                <img
                                                    src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </>
    )
}

export default EditFinanceInfo;