import React, { useEffect, useState } from "react";
import BackButton from "../../../assets/image/BackButton";
import RemoveIcon from "../../../assets/image/RemoveIcon";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AddVendorValidation from '../../../middleware/addVendorValidation';
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import { TERMS_LIST, SUBSIDIARY_LIST, COUNTRY_LIST, STATE_LIST, CITY_LIST, ADD_VENDOR, CURRENCY_LIST } from "../../../utils/apiRoutes";
import { getApi, postApi } from "../../../utils/services";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AddVendor = () => {

    const navigate = useNavigate();
    const transactionList = useTransactionContext();
    const user = JSON.parse(localStorage.getItem("userData"));
    const [formState, setFormState] = useState({
        vendor: '',
        subsidiary: '',
        terms: '',
        addressLine1: '',
        addressLine2: '',
        pincode: '',
        country: '',
        state: '',
        city: '',
        pEmail: '',
        website: '',
        currency: '',
        file: []
    })

    const [termsList, setTermsList] = useState([])
    const [subsidiaryList, setSubsidiaryList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [submitDisable, setSubmitDisable] = useState(false)
    const [loader, setLoader] = useState(false)
    const [attachDocument, setAttachDocument] = useState(false);
    const [relevantDoc, setRelevantDoc] = useState([]);
    const { errors, setErrors, validateForm } = AddVendorValidation();

    const [rows, setRows] = useState([
        { firstName: '', lastName: '', email: '', phoneNo: '', phoneCode: '91', checked: true }
    ]);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const termResult = await getApi(TERMS_LIST)
                setTermsList(termResult.res.terms_list);

                const subsidiaryResult = await getApi(SUBSIDIARY_LIST)
                setSubsidiaryList(subsidiaryResult.res.subsidiary_list);

                const countryResult = await getApi(COUNTRY_LIST)
                setCountryList(countryResult.res.country_list);

                const currencyResult = await getApi(CURRENCY_LIST)
                setCurrencyList(currencyResult.res.currency_list);

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchStates = async () => {

            if (formState.country) {

                const splitedCountryCode = formState.country.split(',')[1];

                try {
                    const result = await getApi(`${STATE_LIST}/${splitedCountryCode}`);
                    setStateList(result.res.state_list);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchStates();
    }, [formState.country]);

    useEffect(() => {
        const fetchCity = async () => {

            if (formState.country && formState.state) {

                const splitedCountryCode = formState.country.split(',')[1];
                const splitedStateCode = formState.state.split(',')[1];

                try {
                    const result = await getApi(`${CITY_LIST}/${splitedCountryCode}/${splitedStateCode}`);
                    setCityList(result.res.city_list);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchCity();
    }, [formState.country, formState.state]);

    const handleChange = (e) => {

        const { name, value, files } = e.target

        if (name === 'vendor') {

            const newVal = value.replace(/[^a-zA-Z\s]/g, '');

            setFormState({
                ...formState,
                [name]: newVal,
            })

        }

        else if (name === 'pincode') {

            const newVal = value.replace(/[^0-9]/g, '');

            setFormState({
                ...formState,
                [name]: newVal,
            })

        }

        else if (name === 'file') {

            setFormState({
                ...formState,
                [name]: Array.from(files),
            })
        }

        else {

            setFormState({
                ...formState,
                [name]: value,
            })

        }

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name];
            return updatedErrors;
        });

    }

    const handleContactChange = (index, e) => {

        const { name, value } = e.target

        if (name === 'firstName' || name === 'lastName') {

            const newVal = value.replace(/[^a-zA-Z\s]/g, '');

            const updatedRows = [...rows];
            updatedRows[index][name] = newVal

            setRows(updatedRows)

        }

        else if (name === 'phoneNo') {

            const newVal = value.replace(/[^0-9]/g, '');

            const updatedRows = [...rows];
            updatedRows[index][name] = newVal

            setRows(updatedRows)

        }

        else if (name === 'email' || name === 'phoneCode') {

            const updatedRows = [...rows];
            updatedRows[index][name] = value

            setRows(updatedRows);
        }

        const updatedErrors = { ...errors };
        if (updatedErrors.rows && updatedErrors.rows[index]) {
            delete updatedErrors.rows[index][name];
        }

        setErrors(updatedErrors);

    }

    const handleAdd = () => {

        if (rows.length <= 2) {

            setRows([...rows, { firstName: '', lastName: '', email: '', phoneNo: '', phoneCode: '91', checked: false }]);
        }
        // else {
        //     toast.error('Maximum 3 contact information is allowed');
        // }
    }

    const handleRemove = (index) => {

        const updatedRows = [...rows];

        updatedRows.splice(index, 1)
        setRows(updatedRows)

        const updatedErrors = { ...errors };
        if (updatedErrors.rows && updatedErrors.rows.length > index) {
            updatedErrors.rows.splice(index, 1);
        }
        setErrors(updatedErrors)

    }

    const handleCheckboxChange = (row) => {

        const updatedRows = rows.map((r) => ({
            ...r,
            checked: r === row ? true : false,
        }));

        setRows(updatedRows);
    };

    const handleFileChange = (e) => {

        const relevantDoc = Array.from(e.target.files);
        setRelevantDoc(relevantDoc);

        const updatedErrors = { ...errors };
        delete updatedErrors.relevantDoc;
        setErrors(updatedErrors)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm(formState.vendor, formState.subsidiary, formState.currency, formState.terms, formState.pEmail, formState.website, formState.addressLine1, formState.country, formState.state, formState.city, formState.pincode, rows, formState.file, relevantDoc);

        if (isValid) {

            setSubmitDisable(true)
            setLoader(true)

            let fileLogo = {}

            if (formState.file.length <= 0) {
                fileLogo = { LogoName: null, LogoPath: null }
            }

            const logoUploadPromises = formState.file.map(async (file) => {

                if (!!file) {

                    const result = await uploadFile("profile", 'logo', 'vendor', file);
                    fileLogo = { LogoName: result.fileName, LogoPath: result.filePath }

                }

            });

            await Promise.all(logoUploadPromises);

            let relevantDocs = {}

            const relevantDocPromises = relevantDoc.map(async (file) => {

                const result = await uploadFile("document", 'relevantdoc', 'vendor', file);
                relevantDocs = { RelevantDocName: result.fileName, RelevantDocPath: result.filePath }

            });

            await Promise.all(relevantDocPromises);

            const result = await postApi(ADD_VENDOR, {
                "vendor": formState.vendor,
                "transactionId": transactionList[3].id,
                "roleId": user.RoleId,
                "subsidiary": Number(formState.subsidiary),
                "terms": Number(formState.terms),
                "currency": Number(formState.currency),
                "addressLine1": formState.addressLine1,
                "addressLine2": formState.addressLine2,
                "pincode": formState.pincode,
                "country": formState.country,
                "state": formState.state,
                "city": formState.city,
                "primary_email": formState.pEmail,
                "website": formState.website,
                "logo": fileLogo,
                "contact_information": rows,
                "relevantDoc": relevantDocs
            });

            if (result.res.success) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.res.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                navigate("/vendors");
            }

            if (!result.res.success) {
                setErrors({ pEmail: result.res.message })
                setSubmitDisable(false)
                setLoader(false)
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Vendor Portal || Vendor</title>
            </Helmet>

            <div className="container-section">

                <div className="vendor-req-sec">
                    <div className="section-header">
                        <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
                            <BackButton />
                        </button>
                        <h3>Vendor Requisition Form</h3>
                    </div>
                    <div className="vendor-req-sec-form form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="vendor" className="form-label">
                                    Legal Name<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="vendor"
                                    className="form-control"
                                    placeholder="Enter legal name"
                                    name="vendor"
                                    value={formState.vendor}
                                    onChange={handleChange}
                                />
                                {errors.vendor && <span className="cstm-err">{errors.vendor}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="subsidiary" className="form-label">
                                    Subsidiary<span className="required">*</span>
                                </label>
                                <select name="subsidiary" id="subsidiary" className="form-select" value={formState.subsidiary} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {subsidiaryList.map((item, index) => (
                                        <option key={index} value={item.Id}>
                                            {item.Name}
                                        </option>
                                    ))}
                                </select>
                                {errors.subsidiary && <span className="cstm-err">{errors.subsidiary}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="currency" className="form-label">
                                    Currency<span className="required">*</span>
                                </label>
                                <select name="currency" id="currency" className="form-select" value={formState.currency} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {currencyList.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.CurrencyCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.currency && <span className="cstm-err">{errors.currency}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="terms" className="form-label">
                                    Payment Terms<span className="required">*</span>
                                </label>
                                <select name="terms" id="terms" className="form-select" value={formState.terms} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {termsList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.Term}
                                        </option>
                                    ))}
                                </select>
                                {errors.terms && <span className="cstm-err">{errors.terms}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="pEmail" className="form-label">
                                    Email<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="pEmail"
                                    className="form-control"
                                    placeholder="Enter email"
                                    name="pEmail"
                                    value={formState.pEmail}
                                    onChange={handleChange}
                                />
                                {errors.pEmail && <span className="cstm-err">{errors.pEmail}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="website" className="form-label">
                                    Website
                                </label>
                                <input
                                    type="text"
                                    id="website"
                                    className="form-control"
                                    placeholder="Enter website"
                                    name="website"
                                    value={formState.website}
                                    onChange={handleChange}
                                />
                                {errors.website && <span className="cstm-err">{errors.website}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="files" className="form-label">
                                    Logo<span className="ms-1 infoIcon"></span>
                                </label>
                                <div className="input-group mmm">
                                    <input type="file" className="form-control" name="file" onChange={handleChange} />
                                    {errors.file && <span className="cstm-err">{errors.file}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="billing-adrs-sec">
                    <div className="section-header">
                        <h3>Billing Address</h3>
                    </div>
                    <div className="billing-adrs-sec-form form-style">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="addressLine1" className="form-label">
                                    Address Line 1<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="addressLine1"
                                    className="form-control"
                                    placeholder="Enter address line 1"
                                    name="addressLine1"
                                    value={formState.addressLine1}
                                    onChange={handleChange}
                                />
                                {errors.addressLine1 && <span className="cstm-err">{errors.addressLine1}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="addressLine2" className="form-label">
                                    Address Line 2
                                </label>
                                <input
                                    type="text"
                                    id="addressLine2"
                                    className="form-control"
                                    placeholder="Enter address line 2"
                                    name="addressLine2"
                                    value={formState.addressLine2}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="country" className="form-label">
                                    Country<span className="required">*</span>
                                </label>
                                <select name="country" id="country" className="form-select" value={formState.country} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {countryList.map((item, index) => (
                                        <option key={index}>
                                            {item.name + "," + item.isoCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.country && <span className="cstm-err">{errors.country}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="state" className="form-label">
                                    State<span className="required">*</span>
                                </label>
                                <select name="state" id="state" className="form-select" value={formState.state} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {stateList.map((item, index) => (
                                        <option key={index}>
                                            {item.name + "," + item.isoCode}
                                        </option>
                                    ))}
                                </select>
                                {errors.state && <span className="cstm-err">{errors.state}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="city" className="form-label">
                                    City<span className="required">*</span>
                                </label>
                                <select name="city" id="city" className="form-select" value={formState.city} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {cityList.map((item, index) => (
                                        <option key={index} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.city && <span className="cstm-err">{errors.city}</span>}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <label htmlFor="pincode" className="form-label">
                                    Zip Code<span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="pincode"
                                    className="form-control"
                                    placeholder="Enter pincode"
                                    name="pincode"
                                    maxLength={6}
                                    value={formState.pincode}
                                    onChange={handleChange}
                                />
                                {errors.pincode && <span className="cstm-err">{errors.pincode}</span>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-info-sec">
                    <div className="section-header justify-content-between">
                        <h3>Contact Information</h3>
                        <button type="button" className="btn btn-sm section-hdr-btn"
                            onClick={handleAdd}
                        >
                            Add More
                        </button>
                    </div>
                    <div className="billing-adrs-sec-form form-style">
                        {rows.map((row, index) => (
                            <div className={row.checked ? 'row primary-card-information' : 'row optional-div-information'} key={index}>
                                <div className="primary-contact">
                                    <Checkbox
                                        {...label}
                                        checked={row.checked}
                                        onChange={() => handleCheckboxChange(row)}
                                        sx={{
                                            "& .MuiSvgIcon-root": { fontSize: 28 }
                                        }}
                                    />{" "}
                                    <label className="ms-1">Primary Contact</label>
                                </div>

                                {!row.checked && <span className="remove-icon" onClick={() => handleRemove(index)}><RemoveIcon /></span>}

                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="firstName" className="form-label">
                                        First Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        className="form-control"
                                        placeholder="Enter first name"
                                        name="firstName"
                                        value={row.firstName}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].firstName && (
                                            <span className="cstm-err">
                                                {errors.rows[index].firstName}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="lastName" className="form-label">
                                        Last Name<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        className="form-control"
                                        placeholder="Enter last name"
                                        name="lastName"
                                        value={row.lastName}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].lastName && (
                                            <span className="cstm-err">
                                                {errors.rows[index].lastName}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="vendor" className="form-label">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        name="email"
                                        value={row.email}
                                        onChange={(e) => handleContactChange(index, e)}
                                    />
                                    {errors.rows &&
                                        errors.rows[index] &&
                                        errors.rows[index].email && (
                                            <span className="cstm-err">
                                                {errors.rows[index].email}
                                            </span>
                                        )}
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                    <label htmlFor="phoneCode" className="form-label">
                                        Phone Number
                                    </label>
                                    <div className="input-group">
                                        <select name="phoneCode" id="phoneCode" className="inp-mrg-sec" value={row.phoneCode} onChange={(e) => handleContactChange(index, e)}>
                                            {countryList.map((item, index) => (
                                                <option key={index}>
                                                    {item.phonecode}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            id="phoneNo"
                                            className="form-control inp-rgt-radius"
                                            placeholder="Enter phone number"
                                            name="phoneNo"
                                            maxlength={10}
                                            value={row.phoneNo}
                                            onChange={(e) => handleContactChange(index, e)}
                                        />
                                        {errors.rows &&
                                            errors.rows[index] &&
                                            errors.rows[index].phoneNo && (
                                                <span className="cstm-err">
                                                    {errors.rows[index].phoneNo}
                                                </span>
                                            )}
                                        <div data-lastpass-icon-root="true"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="cstm-checkbox mb-2">
                            <div className="cstm-checkbox-inner d-flex align-items-center">
                                <input className="form-check-input m-0" type="checkbox"
                                    name="attachDoc"
                                    id="attachDoc"
                                    checked={attachDocument}
                                    onChange={(e) => setAttachDocument(e.target.checked)}
                                />
                                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center">
                                    <label htmlFor="attachDoc" className="ms-2">
                                        Check for relevant document
                                    </label>
                                    <span className="required">*</span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group row mb-3">
                            <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                                <div className="input-group mmm">
                                    <input
                                        type="file"
                                        id="files"
                                        className="form-control"
                                        name="files"
                                        onChange={handleFileChange}
                                        disabled={!attachDocument}
                                    />
                                    {errors.relevantDoc && <span className="cstm-err">{errors.relevantDoc}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mb-20">
                            <div className="cstm-success-btn ms-2">
                                <button type="submit" className="btn" onClick={(e) => handleSubmit(e)} disabled={submitDisable}>
                                    {loader ?
                                        <>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </>
                                        : "Submit"
                                    }
                                </button>
                            </div>
                        </div>
                    </div >
                </div >

            </div>
        </>

    );
};

export default AddVendor;
