import React, { useContext, useState } from 'react';
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { REJECTED_BILL_API } from '../../utils/apiRoutes';
import toast from 'react-hot-toast';
import { useTransactionContext } from '../../utils/helpers/useTransaction';

const RejectAtBill = ({ levelValue, handlerBillList, data }) => {
  const [rejectReason, setRejectReason] = useState("");
  const [level, setLevel] = useState("");
  const modalContext = useContext(ModalContext);
  const user = JSON.parse(localStorage.getItem("userData"));
  const transactionList = useTransactionContext();
  const [roleError, setRoleError] = useState(""); 
  const [rejectReasonError, setRejectReasonError] = useState("");
  const [disable, setDisable] = useState(false);

  const closeModal = modalContext.closeModal;

  const validateForm = () => {
    let isValid = true;
    if (!level) {
      setRoleError("Please select a role !");
      isValid = false;
    }
    if (!rejectReason) {
      setRejectReasonError("Please provide a rejection reason !");
      isValid = false;
    }
    return isValid;
  };

  const handlerRejecTorequester = async () => {
    if (!validateForm()) {
      return;
    }
    setDisable(true);
    const result = await postApi(REJECTED_BILL_API, {
      "billId": data.id,
      "transactionId": transactionList[1].id,
      "roleId": user.RoleId,
      "level": Number(level),
      "rejectionReason": rejectReason
    });
    if (result.res.success) {
      toast.success(result.res.message, {
        className: 'custom-toast-class', // Add your custom class here
      });
      handlerBillList();
      closeModal();
    }
  };

  const onOptionChangeHandler = (event) => {
    setLevel(event.target.value);
    setRoleError("");
  };

  const onRejectReasonChangeHandler = (e) => {
    setRejectReason(e.target.value);
    setRejectReasonError("");
  };

  return (
    <div className='cstm-frm-mdl'>
      <div className="modal-header">
        <h4 className="modal-title">Rejection Reason</h4>
      </div>
      <div className="modal-body">
        <h6>Are you sure want to reject this Bill?</h6>
        <select value={level} className="form-select mb-3" onChange={onOptionChangeHandler}>
          <option value="">Select</option>
          {levelValue.map((option, index) => (
            <option key={index} value={option?.level}>
              {option?.RoleName}
            </option>
          ))}
        </select>
        {roleError && <span className="reject-error-text">{roleError}</span>}
        <div className="form-floating">
          <textarea value={rejectReason} className="form-control reject-modal-text-area" placeholder="Leave a comment here" id="floatingTextarea" rows={20} maxLength={100} onChange={onRejectReasonChangeHandler}></textarea>
          <label htmlFor="floatingTextarea">Reason</label>
        </div>
        {rejectReasonError && <p className="validation-bill-err">{rejectReasonError}</p>}
      </div>
      <div></div>
      <div className="modal-footer">
        <div className="d-flex align-items-center mb-2">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={closeModal}>Cancel</button>
          </div>
          <div className="cstm-reject-btn ms-2">
            <button type="submit" className="btn" onClick={handlerRejecTorequester} disabled={disable}>
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectAtBill;
