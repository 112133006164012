import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import BackButtonWhite from "../../../assets/image/BackButtonWhite";
import { getApi } from "../../../utils/services";
import { BASE_IMAGE_URL } from "../../../utils/services";
import { VIEW_INDIVIDUAL_BILL } from "../../../utils/apiRoutes";
import DownloadIcon from "../../../assets/image/DownloadIcon";
import EyeIcon from "../../../assets/image/EyeIcon";
import moment from "moment/moment";
import { Helmet } from "react-helmet";
import Loader from "../../common/Loader";
import ApproveIndividualBill from "../../modal/ApproveIndividualBill";
import { ModalContext } from "../../../utils/Context";
import { ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, VENDOR_MANAGER_PENDING, VENDOR_VP_PENDING, VP_ID } from "../../../utils/constant";
import RejectIndividualBill from "../../modal/RejectIndividualBill";
import DepartmentPending from "../../modal/DepartmentPending";

const ViewIndividualBill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [Viewdetails, setViewDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const modalContext = useContext(ModalContext);
  const { handleModalData } = modalContext;

  const handleIndividualViewdetails = async () => {
    try {
      setLoader(true);
      const result = await getApi(`${VIEW_INDIVIDUAL_BILL}/${id}`);
      setLoader(false);
      setViewDetails(result.res.individualBill_details);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleIndividualViewdetails();
  }, []);

  let data = {
    id: Number(id),
  };

  const handleApprove = (departmentName) => {
    if (departmentName) {
      let approve = <ApproveIndividualBill handlerIndividualBillList={handleIndividualViewdetails} data={data} />;
      handleModalData(approve, "approve");
    }
    else {
      let ApproveModal = <DepartmentPending data={data} handlerIndividualBillList={handleIndividualViewdetails} />
      handleModalData(ApproveModal, "md");
    }
  };

  const handleReject = () => {
    let reject = <RejectIndividualBill data={data} handlerIndividualBillList={handleIndividualViewdetails} />;
    handleModalData(reject, "reject");
  };

  const handleTDSCreation = () => {
    navigate(`/add-individual-bill-tds/${id}`);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || View Standalone Bill</title>
      </Helmet>
      <Loader isLoading={loader} />
      <div className="container-section">
        <div className="section-inv-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div class="svgsize">
              <BackButtonWhite />
            </div>
          </button>

          <h3> Standalone Bill</h3>
        </div>
        <div className="form-style">
          <div className="data-inv-info">
            <div className="data-inv-group-1">
              <div className="data-details-1">
                <div className="section-sub-heading">
                  <h4>Bill To:</h4>
                  <p>
                    JSTREAMS SOLUTION PVT LIMITED,
                    <br />
                    <span>Address: </span>Unitech Cyber Park, Unit No.- 1005 ,
                    10th Floor, Tower C, Sector 39, Gurugram, Haryana,
                    Pincode: 122003
                  </p>
                </div>
              </div>
              {/* <div className="data-details-2">
                <div className="section-sub-heading">
                  <h4>Vendor Details:</h4>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-info-label">Legal Name</span>
                  <span className="data-info-semicolon">:</span>
                  <span className="data-info-value">
                    {Viewdetails.LegalName}
                  </span>
                </div>
              </div> */}
              <div className="d-flex align-items-between">
                <div className="data-details-2">
                  <div className="section-sub-heading">
                    <h4>Vendor Details:</h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className=" data-info-label">Legal Name</span>
                    <span className="data-info-semicolon">:</span>
                    <span className="data-info-value">
                      {Viewdetails?.LegalName}
                    </span>
                  </div>

                </div>
                <div className="data-details-2-i">
                  <div className="section-sub-heading">
                    <h4>Integration:</h4>
                  </div>
                  <div className="d-flex ">
                    <span className=" data-info-label">NetSuite Id</span>
                    <span className="data-info-semicolon">:</span>
                    <span className="data-info-value">
                      {Viewdetails?.invoiceExternalId ? Viewdetails?.invoiceExternalId : " N/A"}
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div className="data-inv-group-2">
              <div className="data-details-4">
                <div className="d-flex align-items-center">
                  <span className="data-label">Bill#</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails.InvDocumentNo}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-label">Payment Id</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails?.PaymentTransactionId ? (
                      <Link
                        to={`/view-payment/${Viewdetails?.PaymentTransactionId}`}
                        target="_blank"
                        className=""
                      >
                        {Viewdetails?.PaymentId}
                      </Link>
                    ) : Viewdetails?.PaymentId ? (
                      Viewdetails?.PaymentId
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-label">Payment Mode</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails.PaymentMode}
                  </span>
                </div>

                {/* <div className="d-flex align-items-center">
                  <span className="data-label">Payment Type</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {" "}
                    {Viewdetails?.PaymentTypeName
                      ? Viewdetails.PaymentTypeName
                      : "N/A"}
                  </span>
                </div> */}

                <div className="d-flex align-items-center">
                  <span className="data-label">Reference Number</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {Viewdetails?.ReferenceNo
                      ? Viewdetails.ReferenceNo
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-label">Bill Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {" "}
                    {moment(Viewdetails?.CreatedDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-label">Due Date</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">
                    {" "}
                    {moment(Viewdetails?.DueDate)
                      .format("D/MM/YYYY")
                      ?.slice(0, 10)}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span className="data-label">Status</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value pe-0">{Viewdetails?.StatusName}</span>
                </div>
                {Viewdetails?.RejectionReason &&
                  <div className="d-flex align-items-center">
                    <span className="data-label">Rejection Reason</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{Viewdetails?.RejectionReason}</span>
                  </div>
                }
                {Viewdetails?.DepartmentName &&
                  <div className="d-flex align-items-center">
                    <span className="data-label">Department</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value pe-0">{Viewdetails?.DepartmentName}</span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading">
            <h3>Item Description</h3>
          </div>
          <div className="table-responsive  tbl-body-shadow">
            <table className="w-100 table-theme-1">
              <thead>
                <tr>
                  <th className="text-left">Item</th>
                  <th className="text-end">Rate</th>
                  <th className="text-end">Quantity</th>

                  <>
                    {Viewdetails?.LineGST && (
                      <>
                        <th className="text-end">CGST%</th>
                        <th className="text-end">SGST%</th>
                        <th className="text-end">UTGST%</th>
                        <th className="text-end">IGST%</th>
                        <th className="text-end">GST Amount</th>
                      </>
                    )}
                  </>

                  <th className="text-end">Amount</th>
                </tr>
              </thead>
              <tbody>
                {Viewdetails?.InvoiceLines &&
                  Viewdetails?.InvoiceLines.length > 0 ? (
                  Viewdetails.InvoiceLines.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left text-nowrap">{row.ItemName}</td>
                      <td className="text-end text-nowrap">
                        {Viewdetails.CurrencySymbol} {row?.Rate?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        {row?.Quantity?.toFixed(2)}
                      </td>

                      {Viewdetails?.LineGST && (
                        <>
                          <td className="text-end">

                            {row?.cgst ? row?.cgst : 0}
                          </td>
                          <td className="text-end">

                            {row?.sgst ? row?.sgst : 0}
                          </td>
                          <td className="text-end">

                            {row?.utgst ? row?.utgst : 0}
                          </td>
                          <td className="text-end">

                            {row?.igst ? row?.igst : 0}
                          </td>
                          <td className="text-end">
                            &#x20B9; {row?.GST_Total}
                          </td>
                        </>
                      )}
                      <td className="text-end text-nowrap">
                        {Viewdetails.CurrencySymbol} {row?.Amount?.toFixed(2)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={Viewdetails?.LineGST ? "9" : "7"}
                      className="text-center"
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="form-style">
          <div className="section-sub-heading ">
            <h3>File</h3>
          </div>
          <div className="table-responsive tbl-body-shadow mb-20">
            <table className="w-100 table-theme-1">
              <thead>
                <tr>
                  <th className="text-left">File Name</th>
                  <th className="text-center">File Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {Viewdetails?.InvoiceDocuments &&
                  Viewdetails?.InvoiceDocuments.length > 0 ? (
                  Viewdetails.InvoiceDocuments.map((row, index) => (
                    <tr key={index}>
                      <td className="text-left">{row.FileName}</td>
                      <td className="text-center">pdf</td>
                      <td>
                        <div className="cursor-pointer d-flex align-items-center justify-content-center">
                          <div className="cstm-download-btn">
                            <Link
                              to={`${BASE_IMAGE_URL}/${row.Path}`}
                              download
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <DownloadIcon /> Download
                              </button>
                            </Link>
                          </div>
                          <div className="cstm-preview-file-btn ms-2">
                            <Link
                              className="ms-2"
                              to={`/preview-pdf/${btoa(row.Path)}`}
                              target="_blank"
                            >
                              <button type="button" className="btn">
                                <EyeIcon /> Preview
                              </button>
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="view-footer d-flex justify-content-end mb-20">
            <div className="data-details-3">
              <div className="d-flex align-items-center">
                <span className=" data-label">Sub Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {Viewdetails.CurrencySymbol} {Viewdetails?.InvoiceTotal?.toFixed(2)}
                </span>
              </div>
              {Viewdetails?.CGST && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">CGST%</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.CGST}</span>
                </div>
              )}
              {Viewdetails?.SGST && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">SGST%</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.SGST}</span>
                </div>
              )}
              {Viewdetails?.UGST && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">UGST%</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.UTGST}</span>
                </div>
              )}
              {Viewdetails?.IGST && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">IGST%</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.IGST}</span>
                </div>
              )}
              {(Viewdetails?.Tax !== undefined && Viewdetails?.Tax !== null) && (
                <div className="d-flex align-items-center">
                  <span className=" data-label">Sales Tax%</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.Tax}</span>
                </div>
              )}
              {Viewdetails.SubsidiaryId === 1 ?
                <>
                  <div className="d-flex align-items-center">
                    <span className=" data-label">VAT Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {Viewdetails.CurrencySymbol} {Viewdetails?.TaxSubtotal?.toFixed(2)}
                    </span>
                  </div>
                </>
                :
                <>
                  <div className="d-flex align-items-center">
                    <span className=" data-label">GST Total</span>
                    <span className="data-semicolon">:</span>
                    <span className="data-value">
                      {Viewdetails.CurrencySymbol} {Viewdetails?.TaxSubtotal?.toFixed(2)}
                    </span>
                  </div>
                </>
              }
              {(Viewdetails?.TDSAmount !== undefined && Viewdetails?.TDSAmount !== null) && (
                <div className="d-flex align-items-center">
                  <span className="data-label">TDS Amount</span>
                  <span className="data-semicolon">:</span>
                  <span className="data-value">{Viewdetails?.TDSAmount}</span>
                </div>
              )}
              <div className=" view-total-amount d-flex align-items-center mt-3">
                <span className=" data-label text-align-left">Total</span>
                <span className="data-semicolon">:</span>
                <span className="data-value">
                  {Viewdetails.CurrencySymbol} {Viewdetails?.Total?.toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          {user?.RoleId === ADMIN_MANAGER_ID &&
            Viewdetails.StatusId === VENDOR_MANAGER_PENDING && (
              <div className="d-flex align-items-center justify-content-end mb-20">
                <div className="cstm-dngr-btn">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
                <div className="cstm-success-btn ms-2">
                  <button
                    type="submit"
                    className="btn"
                    onClick={() => handleApprove(Viewdetails?.DepartmentName)}
                  >
                    Approve
                  </button>
                </div>
              </div>
            )}

          {user?.RoleId === VP_ID &&
            Viewdetails.StatusId === VENDOR_VP_PENDING && (
              <div className="d-flex align-items-center justify-content-end mb-20">
                <div className="cstm-dngr-btn">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
                <div className="cstm-success-btn ms-2">
                  <button
                    type="submit"
                    className="btn"
                    onClick={() => handleApprove(Viewdetails?.DepartmentName)}
                  >
                    Approve
                  </button>
                </div>
              </div>
            )}

          {user?.RoleId === FINANCE_ID &&
            Viewdetails.StatusId === FINANCE_PENDING && (
              <div className="d-flex align-items-center justify-content-end mb-20">
                <div className="cstm-dngr-btn">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
                <div className="cstm-success-btn ms-2">
                  <button
                    type="submit"
                    className="btn"
                    onClick={handleTDSCreation}
                  >
                    Add TDS
                  </button>
                </div>
              </div>
            )}

        </div>
      </div>

    </>
  );
};

export default ViewIndividualBill;
