import { useState } from 'react';

const AddItemValidation = ({ rows }) => {
  const [errors, setErrors] = useState({});

  const validate = () => {
    let newErrors = {};

    const itemnameSet = new Set();
    const externalidSet = new Set();

    newErrors.rows = [];

    rows.forEach((item, index) => {
      const rowsErrors = {};

      if (!item.itemname.trim()) {
        rowsErrors.itemname = "Enter Item Name";
      } else if (itemnameSet.has(item.itemname)) {
        rowsErrors.itemname = "Duplicate Item Name";
      } else {
        itemnameSet.add(item.itemname);

        // Validate for only numeric or special characters
        const isNumeric = /^[0-9]+$/.test(item.itemname);
        const isSpecialCharacters = /^[^\w\s]+$/.test(item.itemname);

        if (isNumeric || isSpecialCharacters) {
          rowsErrors.itemname = "Invalid Item  Name";
        }
      }

      if (!item.externalid) {
        rowsErrors.externalid = "Enter External Id ";
      } else if (externalidSet.has(item.externalid)) {
        rowsErrors.externalid = "Duplicate External Id ";
      } else {
        externalidSet.add(item.externalid);
      }

      if (!item.description.trim()) {
        rowsErrors.description = "Enter Description ";
      }

      if (Object.keys(rowsErrors).length > 0) {
        newErrors.rows[index] = rowsErrors;
      }
    });

    if (newErrors.rows.length === 0) {
      delete newErrors.rows;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return { errors, setErrors, validate };
};

export default AddItemValidation;
