import React from 'react'

const PreviewIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 27 28" fill="none">
                <path d="M21.6667 18.578V2.33333C21.6667 1.97971 21.5262 1.64057 21.2761 1.39052C21.0261 1.14048 20.687 1 20.3333 1H2.33333C1.97971 1 1.64057 1.14048 1.39052 1.39052C1.14048 1.64057 1 1.97971 1 2.33333V25.6667C1 26.0203 1.14048 26.3594 1.39052 26.6095C1.64057 26.8595 1.97971 27 2.33333 27H20.3333C20.687 27 21.0261 26.8595 21.2761 26.6095C21.5262 26.3594 21.6667 26.0203 21.6667 25.6667V24.6873" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.0884 23.1067L9.52436 12.5167V9.5M20.0884 23.1067L23.1357 20.052M20.0884 23.1067L21.829 24.8507C21.929 24.9507 22.0476 25.0301 22.1783 25.0843C22.3089 25.1384 22.4489 25.1663 22.5904 25.1663C22.7318 25.1663 22.8718 25.1384 23.0024 25.0843C23.1331 25.0301 23.2518 24.9507 23.3517 24.8507L24.8757 23.3233C25.0777 23.1205 25.1912 22.8459 25.1912 22.5597C25.1912 22.2734 25.0777 21.9988 24.8757 21.796L23.1357 20.052M9.52436 9.5H12.6124L23.1357 20.052M9.52436 9.5H3.99902M3.99902 5H18.6657M17.1004 14H18.6657M3.99902 14H11.0044M3.99902 18.5H15.325M3.99902 23H18.6657" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    )
}

export default PreviewIcon