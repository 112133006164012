import { useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStatusRenderer } from '../useStatus';
import ApproveButton from '../../../assets/image/ApproveButton';
import RejectButton from '../../../assets/image/RejectButton';
import edit from "../../../assets/image/edit2.png"
import eyeIcon from "../../../assets/image/eye.png"
import { ADMIN_MANAGER_ID, FINANCE_ID, FINANCE_PENDING, VENDOR_MANAGER_PENDING, VENDOR_REJECT, VENDOR_VP_PENDING, VP_ID, SUPER_ADMIN_ID } from '../../constant';
import { ModalContext } from '../../Context';
import ApproveBillContract from '../../../components/modal/ApproveBillContract';
import FinanceApporveIcon from '../../../assets/image/FinanceApporveIcon';
import RejectBill from '../../../components/modal/RejectBill';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DepartmentPending from '../../../components/modal/DepartmentPending';

// Custom hook for defining FeedsColumns
export const useFeedsColumnBills = (handlerBillList) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const modalContext = useContext(ModalContext);
    const { handleModalData } = modalContext;
    const navigate = useNavigate();

    const handlerApprove = (data) => {
        if (data.DepartmentName) {
            let app = <ApproveBillContract data={data} handlerBillList={handlerBillList} />
            handleModalData(app, "md");
        }
        else {
            let ApproveModal = <DepartmentPending data={data} handlerBillList={handlerBillList} />
            handleModalData(ApproveModal, "md");
        }
    }

    const handlerReject = (data) => {
        let app = <RejectBill data={data} handlerBillList={handlerBillList} />
        handleModalData(app, "md");
    }

    const handlerTds = (data) => {
        navigate(`/add-tds/${data.id}`)
    }

    const handlerBillView = (id) => {
        navigate(`/view-bill/${id}`)
    }

    const editBill = (id) => {
        navigate(`/edit-bill/${id}`)
    }

    const columns = useMemo(() => [
        {
            Header: "Bill Number",
            accessor: "DocumentNo",
        },
        {
            Header: "Purchase Contract",
            accessor: "ContractDocId",
            Cell: ({ row }) => {
                return <><Link className='' to={`/view-purchase-contract/${row?.original?.ContractId}`} target='_blank'>{row?.original.ContractDocumentId}</Link></>;
            },
        },
        {
            Header: "Vendor Name",
            accessor: "LegalName",
        },
        {
            Header: "Grand Total",
            headerStyle: { textAlign: 'right' },
            accessor: "Total",
            Cell: ({ row }) => {
                return <><div className='text-end pe-4'>{row?.original?.CurrencySymbol} {row?.original?.Total?.toFixed(2)}</div> </>;
            },
        },
        {
            Header: "TDS Amount",
            headerStyle: { textAlign: 'right' },
            accessor: "TDSAmount",
            Cell: ({ row }) => {
                return <><div className='text-end pe-4'>{row?.original?.TDSAmount ? <>{row?.original?.CurrencySymbol} {row?.original?.TDSAmount?.toFixed(2)} </> : "N/A"} </div> </>;
            },
        },
        {
            Header: <div className='ps-5'>Status</div>,
            accessor: "Name",
            Cell: ({ row }) => {
                return (
                    <>
                        {useStatusRenderer(row)}
                    </>
                );
            },
            disableSortBy: true,
        },
        {
            Header: "Action",
            accessor: (data) => {
                return (
                    <div className="d-flex align-item-center">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips1">
                                    View
                                </Tooltip>
                            }
                        >
                            <img
                                src={eyeIcon}
                                alt=" "
                                className="cursor-pointer cstm-eye-icon-gap"
                                onClick={() => handlerBillView(data.id)}
                            />
                        </OverlayTrigger>

                        {((data.Status === VENDOR_MANAGER_PENDING &&
                            user.RoleId === ADMIN_MANAGER_ID) ||
                            (data.Status === VENDOR_VP_PENDING && user.RoleId === VP_ID) ||
                            (data.Status === FINANCE_PENDING &&
                                user.RoleId === FINANCE_ID)) && (
                                <span className="d-flex">
                                    {data.Status === FINANCE_PENDING ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips2">
                                                    Add TDS
                                                </Tooltip>
                                            }
                                        >
                                            <span
                                                onClick={() => handlerTds(data)}
                                                className="cursor-pointer cstm-icon-gap"
                                            >
                                                <FinanceApporveIcon />
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips3">
                                                    Approve
                                                </Tooltip>
                                            }
                                        >
                                            <span
                                                onClick={() => handlerApprove(data)}
                                                className="cursor-pointer cstm-icon-gap"
                                            >
                                                <ApproveButton />
                                            </span>
                                        </OverlayTrigger>
                                    )}

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips4">
                                                Reject
                                            </Tooltip>
                                        }
                                    >
                                        <span
                                            onClick={() => handlerReject(data)}
                                            className="cursor-pointer cstm-icon-gap"
                                        >
                                            <RejectButton />
                                        </span>
                                    </OverlayTrigger>
                                </span>
                            )}

                        {(data.Status === VENDOR_REJECT ||
                            (data.Status === VENDOR_MANAGER_PENDING &&
                                user.RoleId === ADMIN_MANAGER_ID)) && (
                                <>
                                    {(user.RoleId !== VP_ID && user.RoleId !== FINANCE_ID && user.RoleId !== SUPER_ADMIN_ID) && (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip className="custom-tooltip cstm-tooltip" id="tooltips5">
                                                    Edit
                                                </Tooltip>
                                            }
                                        >
                                            <img src={edit} alt="" onClick={() => editBill(data.id)} className="cstm-icon-gap cursor-pointer" />
                                        </OverlayTrigger>
                                    )}
                                </>
                            )}
                    </div>

                );
            },
        },
    ], []);

    return columns

};