import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { VENDOR_APPROVE } from "../../utils/constant";
import { getApi, postApi } from "../../utils/services";
import {
  MAP_ITEM,
  VENDOR,
  ITEM_LIST,
  MAP_EDIT_TIEM,
} from "../../utils/apiRoutes";
import { toast } from "react-hot-toast";
import { List } from "@material-ui/core";

const MapItemEditModal = ({
  handleClose,
  show,
  selectedMapItem,
  onEditSuccess,
}) => {
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [removedVendors, setRemovedVendors] = useState([]);
  console.log(removedVendors, "check remove vendor ");
  const [itemsValue, setItemValue] = useState({
    Itemname: selectedMapItem ? selectedMapItem.itemName : "",
    VendorName: selectedMapItem ? selectedMapItem.vendorNames : "",
  });

  console.log(vendorName, "check vendor state");
  console.log(selectedMapItem, "vendor name");
  const [errors, setErrors] = useState({
    Itemname: "",
    vendorName: "",
  });

  const handleVendorList = async () => {
    try {
      let vendorResult = await getApi(VENDOR);
      vendorResult = vendorResult.res.vendor_list.filter(
        (i) => i.Status === VENDOR_APPROVE && i.bankInfoFlag === 1
      );
      setVendorList(vendorResult);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleVendorList();
  }, []);

  const vendorCount = selectedMapItem.vendorId.length;

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // If the field is Externalid, remove non-numeric characters
  //   const sanitizedValue =
  //     name === "vendorName" ? value.replace(/[^a-z]/g, "") : value;

  //   setItemValue((prevValues) => ({
  //     ...prevValues,
  //     [name]: sanitizedValue,
  //   }));

  //   // Clear the corresponding error
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // If the field is Externalid, remove non-numeric characters
    const sanitizedValue =
      name === "vendorName" ? value.replace(/[^a-z]/g, "") : value;
    setItemValue((prevValues) => ({
      ...prevValues,
      [name]: sanitizedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmitData = async () => {
    try {
      // Validation logic
      if (!itemsValue.Itemname) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          Itemname: "Item name is required",
        }));
        return;
      }

      if (!vendorName.length) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          vendorName: "Vendor name is required",
        }));
        return;
      }

      setDisable(true);

      let result;

      if (vendorCount > 0) {
        // Update existing entry
        const payload = {
          vendorId: vendorName,
          itemId: selectedMapItem.itemId,
          removedVendors: removedVendors,
        };
        result = await postApi(MAP_EDIT_TIEM, payload);
        console.log(result);
      } else {
        // Create new entry
        result = await postApi(MAP_ITEM, {
          vendorId: vendorName,
          itemId: selectedMapItem.itemId,
        });
      }

      if (result && result.res && result.res.success === true) {
        toast.success(result.res.message);
        handleClose();
        onEditSuccess();
      } else {
        toast.error(result.res.message);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An unexpected error occurred");
    } finally {
      setDisable(false);
      setLoader(false);
    }
  };

  // const handleVendorNameChange = (selectedOptions) => {
  //   const selectedIds = selectedOptions.map((option) => option?.value);
  //   const selectedNames = selectedOptions.map((option) => option?.label);
  //   setVendorName(selectedIds);
  //   setItemValue((prevItemsValue) => ({
  //     ...prevItemsValue,
  //     VendorName: selectedNames,
  //   }));
  // };
  const handleVendorNameChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option?.value);
    const selectedNames = selectedOptions.map((option) => option?.label);
    setVendorName(selectedIds);
    setItemValue((prevItemsValue) => ({
      ...prevItemsValue,
      VendorName: selectedNames,
    }));

    if (selectedOptions.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        vendorName: "",
      }));
    }

    // Calculate removed vendor names and IDs
    const removedVendorsInfo = itemsValue.VendorName.filter(
      (name) => !selectedNames.includes(name)
    ).map((removedName) => {
      const removedVendor = vendorList.find(
        (item) => item.LegalName === removedName.trim()
      );
      return removedVendor ? removedVendor.id : ""; // Remove the comma from here
    });

    // Store the removed vendor names and IDs in an array
    setRemovedVendors((prevRemovedVendors) => [
      ...prevRemovedVendors,
      ...removedVendorsInfo,
    ]);
  };

  const handleOnChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option?.value);
    setVendorName(selectedIds);
    if (selectedOptions.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        vendorName: "",
      }));
    }
  };

  const initialSelectedVendors = itemsValue.VendorName.map((vendorName) => {
    if (vendorName) {
      const vendor = vendorList.find(
        (item) => item.LegalName === vendorName.trim()
      );
      return {
        label: vendorName.trim(),
        value: vendor ? vendor.id : "",
      };
    } else {
      return null;
    }
  }).filter(Boolean);

  return (
    <>
      {vendorCount > 0 ? (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          animation={false}
          className="modal-wrap edit-section-header"
          backdrop="static"
          keyboard={false}
        >
          <div className="section-header">
            <h3>Edit Map Item</h3>
          </div>
          <div className="form-style">
            <div className="col-md-12 mb-20">
              <div className="form-group">
                <label htmlFor="vendorName" className="form-label">
                  Vendor Name<span className="required">*</span>
                </label>
                <Select
                  isMulti
                  // id="vendorName"
                  // className=""
                  name="vendorName"
                  value={initialSelectedVendors}
                  onChange={handleVendorNameChange}
                  options={vendorList.map((item) => ({
                    value: item.id,
                    label: item.LegalName,
                  }))}
                />
                {errors.vendorName && (
                  <div className="validate_error-div">{errors.vendorName}</div>
                )}
              </div>
            </div>
            <div className=" col-md-12 mb-20">
              <div className="form-group">
                <label htmlFor="itemname" className="form-label">
                  Item Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="itemname"
                  className="form-control"
                  placeholder="Enter item name"
                  name="Itemname"
                  value={itemsValue.Itemname}
                  onChange={handleChange}
                  disabled
                />
                {errors.Itemname && (
                  <div className="validate_error-div">{errors.Itemname}</div>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">
              <div className="cstm-dngr-btn me-2">
                <button type="button" className="btn" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="cstm-success-btn">
                <button
                  type="submit"
                  className="btn "
                  onClick={(e) => handleSubmitData(e)}
                  disabled={disable}
                >
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          animation={false}
          className="modal-wrap edit-section-header"
          backdrop="static"
          keyboard={false}
        >
          <div className="section-header">
            <h3>Map Item</h3>
          </div>
          <div className="form-style">
            <div className="col-md-12 mb-20">
              <div className="form-group">
                <label htmlFor="vendorName" className="form-label">
                  Vendor Name<span className="required">*</span>
                </label>
                <Select
                  isMulti
                  // id="vendorName"
                  // className=""
                  name="vendorName"
                  value={vendorList.find((option) =>
                    vendorName.includes(option.value)
                  )}
                  onChange={handleOnChange}
                  options={vendorList.map((item) => ({
                    value: item.id,
                    label: item.LegalName,
                  }))}
                />
                {errors.vendorName && (
                  <div className="validate_error-div">{errors.vendorName}</div>
                )}
              </div>
            </div>
            <div className=" col-md-12 mb-20">
              <div className="form-group">
                <label htmlFor="itemname" className="form-label">
                  Item Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="itemname"
                  className="form-control"
                  placeholder="Enter item name"
                  name="Itemname"
                  value={itemsValue.Itemname}
                  onChange={handleChange}
                  disabled
                />
                {errors.Itemname && (
                  <div className="validate_error-div">{errors.Itemname}</div>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">
              <div className="cstm-dngr-btn me-2">
                <button type="button" className="btn" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="cstm-success-btn">
                <button
                  type="submit"
                  className="btn "
                  onClick={(e) => handleSubmitData(e)}
                  disabled={disable}
                >
                  {loader ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MapItemEditModal;
