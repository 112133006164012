import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/globle.scss"
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from './utils/helpers/AuthContext.jsx';
import NetworkServices from './utils/networks.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <React.StrictMode>
  <AuthContextProvider>
     <Router>
        <App />
     </Router>
  </AuthContextProvider>
</React.StrictMode>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
