import React, { useEffect, useState } from "react";
import { getApi } from "../../../utils/services";
import { BILLS_API } from "../../../utils/apiRoutes";
import { useFeedsColumnBills } from "../../../utils/helpers/tableHelpers/useFeedColumnBills";
import { FINANCE_PENDING } from "../../../utils/constant";
import { Helmet } from "react-helmet";
import Finance from "../../Table/FinanceBill";
import Loader from "../../common/Loader";

const FinanceDashboard = () => {
  const [bills, setBills] = useState([]);
  const [loader, setLoader] = useState(false);

  const handlerBillList = async () => {
    try {
      setLoader(true);
      let result = await getApi(`${BILLS_API}?billType=0`);
      result = result.res.bill_list.filter(
        (item) => item.Status === FINANCE_PENDING
      );
      setLoader(false);
      setBills(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handlerBillList();
  }, []);

  const billcolumns = useFeedsColumnBills(handlerBillList);

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Home</title>
      </Helmet>

      <Loader isLoading={loader}/>
        <div className="container-section">
          <div className="section-header">
            <h3>Bills</h3>
          </div>
          <div className="form-style">
            <Finance columns={billcolumns} data={bills} mt="mt-4" fn="" />
          </div>
        </div>
      
    </>
  );
};

export default FinanceDashboard;
