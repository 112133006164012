import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../utils/services";
import { EDIT_ITEM } from "../../utils/apiRoutes";
import EditItemValidation from "../../middleware/editItemValidation";

const EditItemModal = ({ handleClose, show, selectedItem, onEditSuccess }) => {
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(false);
  const [itemsValue, setItemValue] = useState({
    itemid:selectedItem ? selectedItem.itemId : "",
    Itemname: selectedItem ? selectedItem.itemName : "",
    Externalid: selectedItem ? selectedItem.ExternalId : "",
    Descriptions: selectedItem ? selectedItem.Description : "",
  });

  const [errors, setErrors] = useState({
    Itemname: "",
    Externalid: "",
    Descriptions: "",
  });

  // Instantiate the validation component
  const { validateForm } = EditItemValidation({
    itemsValue,
    setErrors,
    loader,
    setLoader,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // If the field is Externalid, remove non-numeric characters
    const sanitizedValue = name === "Externalid" ? value.replace(/[^0-9]/g, "") : value;
  
    setItemValue((prevValues) => ({
      ...prevValues,
      [name]: sanitizedValue,
    }));
  
    // Clear the corresponding error
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmitData = async () => {
    if (validateForm()) {
      try {
        setDisable(true);
        const result = await postApi(EDIT_ITEM, {
          id: selectedItem.itemId,
          itemName: itemsValue.Itemname,
          description: itemsValue.Descriptions,
          externalId: Number(itemsValue.Externalid),
        });

        if (result && result.res && result.res.success === true) {
          toast.success(result.res.message);
          handleClose();
          onEditSuccess();
        }
        if (result.res.success === false) {
          setDisable(false);
          const updatedErrors = {};

          if (result.res.message.includes("Item Name")) {
            updatedErrors.Itemname = result.res.message;
          }
          if (result.res.message.includes("External Id")) {
            updatedErrors.Externalid = result.res.message;
          }

          setErrors(updatedErrors);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        animation={false}
        className="modal-wrap edit-section-header"
        backdrop="static" 
        keyboard={false}
      >
        <div className="section-header">
          <h3>Edit Item</h3>
        </div>
        <div className="form-style">
          <div className="col-md-12 mb-20">
            <div className="form-group">
              <label htmlFor="itemname" className="form-label">
                Item Name<span className="required">*</span>
              </label>
              <input
                type="text"
                id="itemname"
                className="form-control"
                placeholder="Enter item name"
                name="Itemname"
                value={itemsValue.Itemname}
                onChange={handleChange}
              />
              {errors.Itemname && (
                <div className="validate_error-div">{errors.Itemname}</div>
              )}
            </div>
          </div>
          <div className=" col-md-12 mb-20">
            <div className="form-group">
              <label htmlFor="externalid" className="form-label">
                External Id<span className="required">*</span>
              </label>
              <input
                type="text"
                id="externalid"
                className="form-control"
                placeholder="Enter external Id"
                name="Externalid"
                value={itemsValue.Externalid}
                onChange={handleChange}
              />
              {errors.Externalid && (
                <div className="validate_error-div">
                  {errors.Externalid}
                </div>
              )}
            </div>
          </div>

          <div className=" col-md-12 mb-20">
            <div className="form-group">
              <label htmlFor="description" className="form-label">
                Description<span className="required">*</span>
              </label>
              <textarea
                id="description"
                className="form-control"
                placeholder="Enter description"
                name="Descriptions"
                maxLength={100}
                value={itemsValue.Descriptions}
                onChange={handleChange}
              />
              {errors.Descriptions && (
                <div className="validate_error-div">
                  {errors.Descriptions}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-end mb-20">
            <div className="cstm-dngr-btn me-2">
              <button type="button" className="btn" onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div className="cstm-success-btn">
              <button
                type="submit"
                className="btn "
                onClick={(e) => handleSubmitData(e)}
                disabled={disable}
              >
                {loader ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditItemModal;
