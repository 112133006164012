import React from 'react'

const ItemIcon=()=> {
  return (
    <div>
        <svg width="18" height="18" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 2.65787L2.65787 4.31574L5.97361 1" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 10.4001L2.65787 12.0579L5.97361 8.74219" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 18.1266L2.65787 19.7845L5.97361 16.4688" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.84277 10.3906H22.0005" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.84277 18.1289H22.0005" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.84277 2.66016H22.0005" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </div>
  )
}

export default ItemIcon;
