import React, { useContext, useState } from 'react'
import { ModalContext } from '../../utils/Context';
import toast from 'react-hot-toast';
import { postApi } from '../../utils/services';
import { PURCHASE_CONTRACT_APROVE } from '../../utils/apiRoutes';
import { useTransactionContext } from '../../utils/helpers/useTransaction';

const ApprovePurchaseContract = ({ handlerPurchaseContractList, data }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const [disable, setDisable] = useState(false)
    const transactionList = useTransactionContext();
    const modalContext = useContext(ModalContext);
    const { closeModal } = modalContext;

    const handlerApproveApi = async () => {
        try {
            setDisable(true)
            let result = await postApi(PURCHASE_CONTRACT_APROVE, {
                "contractId": data.id,
                "transactionId": transactionList[2].id,
                "roleId": user.RoleId
            })
            if (result.res.success) {
                handlerPurchaseContractList()
                toast.success(result.res.message, {
                    className: 'custom-toast-class', // Add your custom class here
                  });
                closeModal()
            }
            else {
                toast.error(result.res.message)
            }

        } catch (error) {
            toast.error(error.message)
            closeModal()
        }
    }

    return (
        <div className=' custom-cnfm-mdl'>

            <div class="modal-body">
                <h2>Are you sure?</h2>
                <h6>Are you sure want to approve this Purchase Contract? This action cannot be undone.</h6>
            </div>
            <div class="modal-footer">
                <div className=" d-flex align-items-center mb-20">
                    <div className="cstm-dngr-btn">
                        <button type="button" className="btn" onClick={closeModal}>Cancel</button>
                    </div>
                    <div className="cstm-success-btn ms-2">
                        <button type="submit" className="btn" onClick={() => handlerApproveApi()} disabled={disable}>
                            Approve
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ApprovePurchaseContract