import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PurchaseContactList from '../pages/purchaseContract/PurchaseContactList'
import { ADMIN_EXECUTIVE_ID, ADMIN_MANAGER_ID, FINANCE_ID, SUPER_ADMIN_ID, VENDORID, VENDOR_APPROVE, VENDOR_REJECT, VP_ID } from '../../utils/constant';
import AdminExecutiveDashboard from '../pages/dashboard/AdminExecutiveDashboard';
import VendorsList from '../pages/vendor/VendorsList';
import VpDashboard from '../pages/dashboard/VpDashboard';
import AdminManagerDashboard from '../pages/dashboard/AdminManagerDashboard';
import BillList from '../pages/bill/BillList';
import IndividualBillList from '../pages/IndividualBill/IndividualBillList';
import ChangePassword from '../pages/changePassword/StaffChangePassword';
import AddContract from '../pages/purchaseContract/AddContract';
import EditContract from '../pages/purchaseContract/EditContract';
import AddVendor from '../pages/vendor/AddVendor';
import ViewContract from '../pages/purchaseContract/ViewContract';
import ViewVendor from '../pages/vendor/ViewVendor';
import BillEdit from '../pages/bill/BillEdit';
import BillAdd from '../pages/bill/BillAdd';
import FinanceChangePassword from '../pages/changePassword/FinanceChangePassword';
import ViewBill from '../pages/bill/ViewBill';
import FinanceDashboard from '../pages/dashboard/FinanceDashboard';
import SuperAdminDashboard from '../pages/dashboard/SuperAdminDashboard';
import AddTds from '../pages/finance/AddTds';
import PreviewFile from '../pages/previewPdf/PreviewFile';
import IndividualBill from '../pages/bill/IndividualBill';
import ViewPayment from '../pages/payment/ViewPayment';
import Report from '../pages/report/Report';
import ViewIndividualBill from '../pages/bill/ViewIndividualBill';
import AddItem from '../pages/item/AddItem';
import StepperForm from '../pages/vendor/onboarding/StepperForm';
import EditProfile from '../pages/vendor/EditProfile';
import EditVendor from '../pages/vendor/EditVendor';
import ApprovalFlow from '../pages/approvalFlow/ApprovalFlow';
import TerminologyList from '../pages/terminology/TerminologyList';
import VendorDashboard from '../pages/dashboard/VendorDashboard';
import EditIndividualBill from '../pages/bill/EditIndividualBill';
import AddIndividualBillTds from '../pages/finance/AddIndividualBillTds';

const InnerContainer = ({ ViewVendordetails }) => {
   const user = JSON.parse(localStorage.getItem("userData"));

   return (
      <div>
         <Routes>
            {
               user.RoleId === VENDORID ?
                  (ViewVendordetails.Status === VENDOR_APPROVE && ViewVendordetails.bankInfoFlag === 0 || ViewVendordetails.Status === VENDOR_REJECT && ViewVendordetails.bankInfoFlag === 1) ?
                     <>
                        <Route path="/" element={<StepperForm />} />
                        <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                        <Route path="/profile/:id" element={<ViewVendor />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                     </>
                     :
                     <>
                        <Route path="/" element={<VendorDashboard />} />
                        <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                        <Route path="/contract-listing" element={<PurchaseContactList />} />
                        <Route path="/profile/:id" element={<ViewVendor />} />
                        <Route path="/edit-profile" element={<EditProfile />} />
                        <Route path="/invoice-listing" element={<BillList />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                        <Route path="/add-bill/:id" element={<BillAdd />} />
                        <Route path="/edit-bill/:id" element={<BillEdit />} />
                        <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                        <Route path="/view-bill/:id" element={<ViewBill />} />
                        <Route path="/individual-bill" element={<IndividualBill />} />
                        <Route path="/edit-individual-bill/:id" element={<EditIndividualBill />} />
                        <Route path="/individual-bill-list" element={<IndividualBillList />} />
                        <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                     </>
                  :
                  user.RoleId === ADMIN_EXECUTIVE_ID ?
                     <>
                        <Route path="/" element={<AdminExecutiveDashboard />} />
                        <Route path="/add-executive-contract" element={<AddContract />} />
                        <Route path="/edit-executive-contract/:id" element={<EditContract />} />
                        <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                        <Route path="/contract-listing" element={<PurchaseContactList />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                        <Route path='/add-vendor' element={<AddVendor />} />
                        <Route path='/edit-vendor/:id' element={<EditVendor />} />
                        <Route path="/vendors" element={<VendorsList />} />
                        <Route path="/invoice-listing" element={<BillList />} />
                        <Route path="/view-bill/:id" element={<ViewBill />} />
                        <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                        <Route path="/view-payment/:id" element={<ViewPayment />} />
                        <Route path="/add-item" element={<AddItem />} />
                        <Route path="/individual-bill" element={<IndividualBill />} />
                        <Route path="/edit-individual-bill/:id" element={<EditIndividualBill />} />
                        <Route path="/individual-bill-list" element={<IndividualBillList />} />
                        <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                        <Route path="/view-vendor/:id" element={<ViewVendor />} />
                        <Route path="/add-bill/:id" element={<BillAdd />} />
                        <Route path="/edit-bill/:id" element={<BillEdit />} />
                     </>
                     :
                     user.RoleId === ADMIN_MANAGER_ID ?
                        <>
                           <Route path="/" element={<AdminManagerDashboard />} />
                           <Route path="/add-manager-contract" element={<AddContract />} />
                           <Route path="/edit-executive-contract/:id" element={<EditContract />} />
                           <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                           <Route path="/contract-listing" element={<PurchaseContactList />} />
                           <Route path="/vendors" element={<VendorsList />} />
                           <Route path="/invoice-listing" element={<BillList />} />
                           <Route path="/individual-bill-list" element={<IndividualBillList />} />
                           <Route path="/individual-bill" element={<IndividualBill />} />
                           <Route path="/edit-individual-bill/:id" element={<EditIndividualBill />} />
                           <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                           <Route path="/change-password" element={<ChangePassword />} />
                           <Route path="/view-vendor/:id" element={<ViewVendor />} />
                           <Route path="/view-bill/:id" element={<ViewBill />} />
                           <Route path="/edit-bill/:id" element={<BillEdit />} />
                           <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                           <Route path="/report" element={<Report />} />
                           <Route path="/view-payment/:id" element={<ViewPayment />} />
                           <Route path="/add-bill/:id" element={<BillAdd />} />
                        </>
                        :
                        user.RoleId === VP_ID ?
                           <>
                              <Route path="/" element={<VpDashboard />} />
                              <Route path="/contract-listing" element={<PurchaseContactList />} />
                              <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                              <Route path="/vendors" element={<VendorsList />} />
                              <Route path="/invoice-listing" element={<BillList />} />
                              <Route path="/view-bill/:id" element={<ViewBill />} />
                              <Route path="/individual-bill-list" element={<IndividualBillList />} />
                              <Route path="/individual-bill" element={<IndividualBill />} />
                              <Route path="/change-password" element={<ChangePassword />} />
                              <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                              <Route path="/view-payment/:id" element={<ViewPayment />} />
                              <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                              <Route path="/report" element={<Report />} />
                              <Route path="/view-vendor/:id" element={<ViewVendor />} />
                           </>
                           :
                           user.RoleId === FINANCE_ID ?
                              <>
                                 <Route path="/" element={<FinanceDashboard />} />
                                 <Route path="/contract-listing" element={<PurchaseContactList />} />
                                 <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                                 <Route path="/vendors" element={<VendorsList />} />
                                 <Route path="/invoice-listing" element={<BillList />} />
                                 <Route path="/view-bill/:id" element={<ViewBill />} />
                                 <Route path="/add-tds/:id" element={<AddTds />} />
                                 <Route path="/add-individual-bill-tds/:id" element={<AddIndividualBillTds />} />
                                 <Route path="/change-password" element={<FinanceChangePassword />} />
                                 <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                                 <Route path="/view-payment/:id" element={<ViewPayment />} />
                                 <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                                 <Route path="/individual-bill-list" element={<IndividualBillList />} />
                                 <Route path="/report" element={<Report />} />
                              </>
                              :
                              user.RoleId === SUPER_ADMIN_ID ?
                                 <>
                                    <Route path="/" element={<SuperAdminDashboard />} />
                                    <Route path="/contract-listing" element={<PurchaseContactList />} />
                                    <Route path="/view-purchase-contract/:id" element={<ViewContract />} />
                                    <Route path="/invoice-listing" element={<BillList />} />
                                    <Route path="/view-bill/:id" element={<ViewBill />} />
                                    <Route path="/change-password" element={<ChangePassword />} />
                                    <Route path="/view-payment/:id" element={<ViewPayment />} />
                                    <Route path="/view-individual-bill/:id" element={<ViewIndividualBill />} />
                                    <Route path="/individual-bill-list" element={<IndividualBillList />} />
                                    <Route path="/report" element={<Report />} />
                                    <Route path="/preview-pdf/:id" element={<PreviewFile />} />
                                    <Route path="/approval-flow" element={<ApprovalFlow />} />
                                    <Route path="/terminology" element={<TerminologyList />} />
                                 </>
                                 :
                                 <Route path="/" element={" PAGE NOT FOUND 404 ERROR "} />
            }
         </Routes>
      </div>
   )
}

export default InnerContainer