import React from 'react'

const Sidebarinvoiceicon = () => {

    return (
        <>
           <div>
                <svg class="iconsvg" width="18" height="18" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="iconpath" d="M21.0001 4.63158V6.82868C21.0001 8.26315 20.0001 9.17104 18.4201 9.17104H15.0001V2.82487C15.0001 1.8171 15.9101 1 17.0201 1C18.1101 1.00908 19.1101 1.40855 19.8301 2.06224C20.5501 2.725 21.0001 3.63289 21.0001 4.63158Z" stroke="#fff" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path class="iconpath" d="M1 9.17105V18.25C1 19.0035 1.93997 19.4302 2.59996 18.9763L4.30997 17.8142C4.70996 17.5418 5.26996 17.5781 5.62996 17.905L7.28992 19.4212C7.67992 19.7752 8.31995 19.7752 8.70995 19.4212L10.3899 17.8959C10.7399 17.5781 11.2999 17.5418 11.6899 17.8142L13.3999 18.9763C14.0599 19.4212 14.9999 18.9945 14.9999 18.25V2.81579C14.9999 1.8171 15.8999 1 16.9998 1H5.99995H4.99996C1.99999 1 1 2.62513 1 4.63158V5.53947" stroke="#fff" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </>
    )
}
export default Sidebarinvoiceicon;