import { useState } from 'react';
import { ADMIN_MANAGER_ID } from '../utils/constant';

const EditBillValidation = () => {
  const [errors, setErrors] = useState({});

  const isValidFileType = (file) => {
    const allowedTypes = ["application/pdf"];
    return allowedTypes.includes(file.type);
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const validateForm = (BillDate, lineGSTcheck, Files, Cgst, Sgst, UTgst, Igst, Tax, InvoiceTotal, subsidiaryId, selectedFiles, lineItems, roleId, departmentId) => {

    let errors = {};
    let formIsValid = true;

    if (!BillDate) {
      formIsValid = false;
      errors.billDate = "Select Bill Date"
    }

    if (parseFloat(InvoiceTotal) === 0) {
      formIsValid = false;
      errors.subtotal = "Subtotal can't be zero !";
    }

    if (!lineGSTcheck) {
      if (!Cgst && !Sgst && !UTgst && !Igst && subsidiaryId === 2) {
        formIsValid = false;
        errors.taxError = "One of the GST Field is required !";
      }
      if (!Tax && subsidiaryId === 1) {
        formIsValid = false;
        errors.taxError = "Sales Tax is required !";
      }
    }
    else {

      const missingQuantityLines = lineItems.filter((item) => {
        return (
          (item.Quantity <= 0 || item.Quantity === '') && (item.CGST || item.SGST || item.UTGST || item.IGST)
        );
      });

      const missingGstLines = lineItems.filter((item) => {
        return (
          (item.Quantity > 0) &&
          (item.CGST === null || item.CGST === '') &&
          (item.SGST === null || item.SGST === '') &&
          (item.UTGST === null || item.UTGST === '') &&
          (item.IGST === null || item.IGST === '')
        );
      });

      if (missingGstLines.length > 0) {
        alert('One of the GST field is required if quantity is there');
        return;
      }
      else if (missingQuantityLines.length > 0) {
        alert('Quantity is required if any GST field is there');
        return;
      }
    }

    if (Files.length === 0) {
      if (selectedFiles.length === 0) {
        formIsValid = false;
        errors.files = "Please upload a document !";
      }
      else {
        for (const item of selectedFiles) {
          if (!isValidFileType(item)) {
            formIsValid = false;
            errors.files = "Only PDF is allowed !";
            break;
          }
          if (item.size > MAX_FILE_SIZE) {
            formIsValid = false;
            errors.files = "Maximum allowed file size is 2MB !";
            break;
          }
          if (selectedFiles.length > 5) {
            formIsValid = false;
            errors.files = "Only 5 files are allowed !";
            break;
          }
        }
      }
    }
    else {
      for (const item of selectedFiles) {
        if (!isValidFileType(item)) {
          formIsValid = false;
          errors.files = "Only PDF is allowed !";
          break;
        }
        if (item.size > MAX_FILE_SIZE) {
          formIsValid = false;
          errors.files = "Maximum allowed file size is 2MB !";
          break;
        }
        if (selectedFiles.length + Files.length > 5) {
          formIsValid = false;
          errors.files = "Only 5 files are allowed !";
          break;
        }
      }
    }

    if (roleId === ADMIN_MANAGER_ID) {
      if (!departmentId) {
        formIsValid = false;
        errors.departmentId = "Select Department";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default EditBillValidation;
