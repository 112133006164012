import React, { useState, useEffect } from 'react'
import exporticon from "../../../assets/image/Excel_Download_Icon.png";
import { getApi } from '../../../utils/services';
import { BILLS_API, SUBSIDIARY_LIST, VENDOR } from '../../../utils/apiRoutes';
import { Link } from 'react-router-dom';
import moment from "moment";
import SpinnerIcon from "../../../assets/image/SpinnerIcon";
import { VENDOR_APPROVE } from "../../../utils/constant";
import Select from "react-select";
import Loader from '../../common/Loader';

const IndividualBillReport = () => {

  const monthStartDate = moment().startOf('month');
  const monthEndDate = moment().endOf('month');

  const [fromDate, setFromDate] = useState(monthStartDate.format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(monthEndDate.format('YYYY-MM-DD'));
  const [paymentId, setPaymentId] = useState('');
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState('');
  const [individualBillReportList, setIndividualBillReportList] = useState([])
  const [loader, setLoader] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const [subsidiaryId, setSubsidiaryId] = useState(1);
  const [subsidiaryList, setSubsidiaryList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const handleSubsidiaryData = async () => {
    const result = await getApi(SUBSIDIARY_LIST);
    setSubsidiaryList(result.res.subsidiary_list);
  }

  useEffect(() => {
    handleSubsidiaryData();
  }, []);

  const handleVendorList = async () => {
    try {
      let vendorResult = await getApi(VENDOR);
      vendorResult = vendorResult.res.vendor_list.filter((i) => i.Status === VENDOR_APPROVE && i.bankInfoFlag === 1);
      setVendorList(vendorResult);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleVendorList();
  }, []);

  useEffect(() => {
    const handleIndividualBillReport = async () => {
      setLoader(true)
      const result = await getApi(`${BILLS_API}?billType=1&startDate=${fromDate}&endDate=${toDate}&subsidiaryId=${subsidiaryId}`)
      setLoader(false)
      setIndividualBillReportList(result.res)
      const totalGrandAmount = result.res.bill_list.reduce((total, obj) => total + obj.Total, 0);
      setGrandTotal(totalGrandAmount)
      setCurrencySymbol(result.res.bill_list?.find(item => item.SubsidiaryId === subsidiaryId)?.CurrencySymbol);
    }
    handleIndividualBillReport()
  }, [])

  const handleExportData = () => {
    const csvData = [
      ['Bill Number', 'PaymentID', 'Vendor Name', 'Bill Date', 'Created Date', 'GrandTotal', 'Status'],
      ...individualBillReportList.bill_list.map(person => [person.DocumentNo, `${person.UserPaymentId ? person.UserPaymentId : "N/A"}`, person.LegalName, moment(person.BillDate).format('D/MM/YYYY')?.slice(0, 10), moment(person.CreatedAt).format('D/MM/YYYY')?.slice(0, 10), person.Total, person.StatusName]),
      [], ["", "", "", "", "Total", grandTotal, ""]
    ];
    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Bill.csv';
    link.click();
    URL.revokeObjectURL(url);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    const result = await getApi(`${BILLS_API}?billType=1&startDate=${fromDate}&endDate=${toDate}&paymentId=${paymentId}&subsidiaryId=${subsidiaryId}&vendorName=${vendorName}`)
    setLoader(false)
    setIndividualBillReportList(result.res)
    const totalGrandAmount = result.res.bill_list.reduce((total, obj) => total + obj.Total, 0);
    setGrandTotal(totalGrandAmount)
    const currency = result.res.bill_list?.find(item => item.SubsidiaryId === subsidiaryId)?.CurrencySymbol;
    setCurrencySymbol(currency);
  };

  return (
    <form>
      <div className="form-style">
        <div className="report-inpfiled-container">

          <div className="field-flex mb-20">
            <label htmlFor="vendorName" className="form-label">
              Vendor Name
            </label>
            <Select
              id="vendorName"
              className=""
              name="vendorName"
              value={vendorList.find((item) => item.id === vendorName)}
              onChange={(item) => setVendorName(item?.value || "")}
              options={vendorList.map((item) => ({
                value: item.LegalName,
                label: item.LegalName,
              }))}
            />
          </div>
          <div className="field-flex mb-20">
            <label htmlFor="paymentId" className="form-label">
              Payment Id
            </label>

            <input type="text" className="form-control" id="paymentId" placeholder="Enter payment Id" value={paymentId} onChange={(e) => setPaymentId(e.target.value)} />
          </div>

          <div className="field-flex mb-20">
            <label htmlFor=" subsidiary" className="form-label">
              Subsidiary
            </label>
            <select
              id="subsidiary"
              className="form-select"
              name="subsidiary"
              value={subsidiaryId}
              onChange={(e) => setSubsidiaryId(Number(e.target.value))}
            >
              <option value="">Select</option>
              {subsidiaryList.map((item, index) => (
                <option key={index} value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>

          <div className="field-flex mb-20">
            <label htmlFor="fromDate" className="form-label">
              From Date
            </label>
            <input
              type="date"
              id="fromDate"
              className="form-control"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            ></input>
          </div>
          <div className="field-flex mb-20">
            <label htmlFor="toDate" className="form-label">
              To Date
            </label>

            <input
              type="date"
              id="toDate"
              className="form-control"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="field-flex align-self-end mb-20">
            <div className="cstm-success-btn">
              <button type="submit" className="btn" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className='m-0' />

      <Loader isLoading={loader} />
      <>
        {
          individualBillReportList.record_count === 0 ?
            <div className="form-style">
              <div className='section-sub-heading'>
                <h3 className="text-center">{individualBillReportList.message}</h3>
              </div>
            </div>
            :
            <div className="form-style pb-4">
              <div className='d-flex align-items-center justify-content-between'>
                <div className='section-sub-heading'>
                  <h3>Standalone Bill</h3>
                </div>
                <div className='Export-Icon' onClick={handleExportData}>
                  <img src={exporticon} alt="" className='cursor-pointer' />
                </div>
              </div>
              <div className='table-responsive tbl-body-shadow'>
                <table className="w-100 table-theme-1">
                  <thead>
                    <tr>
                      <th className="text-right">Bill Number</th>
                      <th className="text-left">Payment ID</th>
                      <th className="text-left">Vendor Name</th>
                      <th className="text-center">Bill Date</th>
                      <th className="text-center">Created Date</th>
                      <th className="text-end">Grand Total</th>
                      <th className="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {individualBillReportList.bill_list?.map((row, index) => (
                      <tr key={index}>
                        <td className="text-right"> <Link className="" to={`/view-individual-bill/${row.id}`} target="_blank" >{row.DocumentNo}</Link></td>
                        <td className="text-left">{row?.PaymentId ? row?.PaymentId : "N/A"}</td>
                        <td className="text-left">{row?.LegalName}</td>
                        <td className="text-center">{moment(row?.BillDate).format('D/MM/YYYY')?.slice(0, 10)}</td>
                        <td className="text-center">{moment(row?.CreatedAt).format('D/MM/YYYY')?.slice(0, 10)}</td>
                        <td className="text-end">{row?.CurrencySymbol} {row?.Total?.toFixed(2)}</td>
                        <td className="text-left">{row?.StatusName}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="total-show" >
                      <td className="text-end text-white table-footer"></td>
                      <td className="text-end text-white table-footer"></td>
                      <td className="text-end text-white table-footer"></td>
                      <td className="text-end text-white table-footer"></td>
                      <td className="text-left text-white text-center">Total</td>
                      <td className="text-end text-white">{currencySymbol} {grandTotal && grandTotal?.toFixed(2)}</td>
                      <td className="text-end text-white"></td>
                    </tr>
                  </tfoot>

                </table>
              </div>
            </div>
        }
      </>

    </form>
  )
}

export default IndividualBillReport;