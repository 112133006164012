import React, { useState, useEffect } from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { SUBSIDIARY_LIST } from "../../utils/apiRoutes";
import { getApi } from "../../utils/services";

// Sample data for the table

const Vendor = ({ columns, data }) => {

  const [subsidiaryList, setSubsidiaryList] = useState([]);
  const [subsidiaryId, setSubsidiaryId] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  const handleSubsidiaryList = async () => {
    const result = await getApi(SUBSIDIARY_LIST);
    setSubsidiaryList(result.res.subsidiary_list);

    const filtered = data.filter(item => item.SubsidiaryId === subsidiaryId);
    setFilteredData(filtered);
  };

  useEffect(() => {
    handleSubsidiaryList();
  }, [subsidiaryId, data]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  const totalItem = filteredData?.length;

  const handlePagination = (newPage) => {
    if (newPage > pageIndex) {
      nextPage();
    } else if (newPage < pageIndex) {
      previousPage();
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = useTable(
    {
      columns,
      data: filteredData, // Use filteredData instead of 'data'
      defaultColumn, // Be sure to pass the defaultColumn option
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: columns[0].accessor, desc: true }],
      }, // Initial page index and page size
    },
    useFilters, // useFilters hook
    useGlobalFilter, // useGlobalFilter hook
    useSortBy, // useSortBy hook
    usePagination // usePagination hook
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <input
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
            className="form-control mb-3"
          />
        </div>
        <div className="col-md-3 mb-3">
          <select
            name="subsidiary"
            id="subsidiary"
            className="form-select"
            value={subsidiaryId}
            onChange={(e) => setSubsidiaryId(Number(e.target.value))}
          >
            {subsidiaryList?.map((item, index) => (
              <option key={index} value={item?.Id}>
                {item?.Name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className=" table-responsive  tbl-body-shadow">
        <table className="w-100  table-theme-1" {...getTableProps()} >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div >
                      {column.render('Header')}
                      {(columnIndex !== columns.length - 1) && (columnIndex !== columns.length - 2) && (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <span> 🔽</span>
                          ) : (
                            <span> 🔼</span>
                          )
                        ) : (
                          <span> ⬆️⬇️</span>
                        )
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} style={{ padding: '10px', border: '1px solid black' }}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {page.length === 0 && ( // Display a "no data found" row if there are no results to display
              <tr>
                <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  No data found.
                </td>
              </tr>
            )}

          </tbody>
        </table>
      </div>

      {/* pagination two */}

      {/* Pagination */}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
        <PaginationControl
          page={pageIndex + 1}
          between={4}
          total={totalItem}
          limit={pageSize}
          changePage={(page) => {
            handlePagination(page - 1);
          }}
          ellipsis={1}
        />
      </div>
    </div>
  );
};

export default Vendor;