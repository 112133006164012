import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../assets/image/logouser.png";
import downArrow from "../../assets/image/downaarrow-white.png";
import subnavicon from "../../assets/image/Polygon.svg";
import icon from "../../assets/image/streamlogo.png"
import useSidebarSwitch from "../../utils/helpers/useSidebar";
import { VENDORID } from "../../utils/constant";

const Sidebar = ({ViewVendordetails,setTogglerBar}) => {
  const [activeLink, setActiveLink] = useState({ 0: true });
  const [listShow, setListShow] = useState({ 0: true });
  const [innerActiveLink, setInnerActiveLink] = useState("");
  const user = JSON.parse(localStorage.getItem("userData"));
  const sidebarConfig = useSidebarSwitch(user.RoleId, ViewVendordetails);
  const location = useLocation();
  const navigate = useNavigate();

  const listHandler = (id, sublist, listRef) => {
    setActiveLink({ [id]: true });
    if (sublist !== undefined && !listShow[listRef]) {
      setListShow({ [listRef]: true });

    } else {
      setListShow({ [listRef]: false });
    }
  };

  useEffect(() => {
    let _link = location.pathname?.split("/");
    setInnerActiveLink(_link[1]);
  }, [location]);

  const handleProfileEdit = () => {
    if (user.RoleId === VENDORID) {
      navigate(`/profile/${user.id}`);
    }
  }

  return (
    <>
      <div className="menuhead">
        <div className='sidenav-logo'>
          <img src={icon} alt="" />
        </div>
        <div className="nav-avater">
          <div className="nav-profile-image ">
            <div>
              <img className="cursor-pointer" src={Avatar} alt="profile" onClick={handleProfileEdit} />
            </div>
          </div>
          <span className='login-status cursor-pointer' onClick={handleProfileEdit}>{user.Name || user.LegalName || user.FirstName}</span>
          <span className="user-role-id">{user.RoleName}</span>
        </div>
      </div>

      <ul className="side-menu">
        {sidebarConfig.map((list, id) => {
          let sublist = list.sublist;
          let listRef = list.ref;
          let labelText = list.label;
          let path = list.path;
          let active = list.activelogo;
          // let nonactive = list.nonactivelogo;

          return (
            <React.Fragment key={id}>
              <li
                id={id}
                onClick={() => {
                  listHandler(id, sublist, listRef);
                  if (path !== "#") {
                    setTogglerBar(false);
                  }
                }}
              >
                <Link
                  to={`${path}`}
                  onClick={() => { listHandler(id, sublist, listRef) }}
                  className={`${location.pathname === list.path ? "active" : `/${innerActiveLink}` === list.path ? "active" : ""}`}
                >
                  <div className="navIcon d-flex align-items-center">
                    <div className={`${activeLink[id] ? "imagelogo" : "imagelogos"}  `}>
                      {active}
                    </div>
                    <p className="ms-3"> {labelText}</p>
                  </div>
                  {sublist && <img src={downArrow} className={`downArrow ${listShow[listRef] && "upArrow"}`} alt="logo" />}
                </Link>
              </li>

              {sublist && (
                <li className="p-0">
                  <ul className={listShow[listRef] && "submenu"}>
                    {list.sublist.map((list, id) => (
                      <li key={id}
                      onClick={() => setTogglerBar(false)}
                      >
                        <NavLink
                          to={`${list.path}`}
                          activeclassname="active"
                          className="subnavlist"
                        >
                          <div className="subnavIcon d-flex ps-4 align-items-center">
                            <div className="me-2 pb-1">
                              <img src={subnavicon} alt="" />
                            </div>
                            <p>{list.label}</p>
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </>
  );
};

export default Sidebar;