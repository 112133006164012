import { useState } from 'react';
import { ADMIN_MANAGER_ID } from '../utils/constant';

const AddIndividuallBillValidation = () => {
  const [errors, setErrors] = useState({});

  const isValidFileType = (file) => {
    const allowedTypes = ["application/pdf"];
    return allowedTypes.includes(file.type);
  };

  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const validateForm = (vendorId, billDate, itemDescription, subsidiaryId, subTotal, tax, subTotalUSA, roleId, departmentId, individualBillDocument, salesTax) => {

    let errors = {};
    errors.itemDescription = []
    let formIsValid = true;

    if (!vendorId) {
      formIsValid = false;
      errors.vendorId = "Select Vendor"
    }
    if (!billDate) {
      formIsValid = false;
      errors.billDate = "Select Bill Date";
    }
    itemDescription.forEach((item, index) => {
      const lineItemErrors = {};

      if (!item.itemId) {
        formIsValid = false;
        lineItemErrors.itemId = "Select Item";
      }
      if (!item.rate) {
        formIsValid = false;
        lineItemErrors.rate = "Enter Rate";
      }
      if (item.rate && item.rate == 0) {
        formIsValid = false;
        lineItemErrors.rate = "Rate can't be zero";
      }
      if (!item.quantity) {
        formIsValid = false;
        lineItemErrors.quantity = "Enter Quantity";
      }
      if (item.quantity && item.quantity == 0) {
        formIsValid = false;
        lineItemErrors.quantity = "Quantity can't be zero";
      }
      if (Object.keys(lineItemErrors).length > 0) {
        errors.itemDescription[index] = lineItemErrors;
      }
      return lineItemErrors;
    });

    if (errors.itemDescription.length === 0) {
      delete errors.itemDescription;
    }

    if (vendorId && subsidiaryId === 2) {
      if (subTotal === 0) {
        formIsValid = false;
        errors.subTotal = "Subtotal cannot be zero !";
      }
      if (!tax.cgst && !tax.sgst && !tax.utgst && !tax.igst) {
        formIsValid = false;
        errors.tax = "One of the GST Field is required !";
      }
    }

    if (vendorId && subsidiaryId === 1) {
      if (subTotalUSA === 0) {
        formIsValid = false;
        errors.subTotalUSA = "Subtotal cannot be zero !";
      }
      if (!salesTax) {
        formIsValid = false;
        errors.salesTax = "Tax is required !";
      }
    }

    if (roleId === ADMIN_MANAGER_ID) {
      if (!departmentId) {
        formIsValid = false;
        errors.departmentId = "Select Department";
      }
    }

    if (!individualBillDocument || individualBillDocument.length === 0) {
      formIsValid = false;
      errors.files = "Please upload a document !";
    }
    else if (individualBillDocument) {
      for (const file of individualBillDocument) {
        if (!isValidFileType(file)) {
          formIsValid = false;
          errors.files = "Only PDF is allowed !";
          break;
        }
        if (file.size > MAX_FILE_SIZE) {
          formIsValid = false;
          errors.files = "Maximum allowed file size is 2MB !";
          break;
        }
        if (individualBillDocument.length > 5) {
          formIsValid = false;
          errors.files = "Only 5 files are allowed !";
          break;
        }
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  return { errors, setErrors, validateForm };
};

export default AddIndividuallBillValidation;
