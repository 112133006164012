import { useState } from 'react';
import { ADMIN_MANAGER_ID } from '../utils/constant';

const EditIndividuallBillValidation = () => {
    const [errors, setErrors] = useState({});

    const isValidFileType = (file) => {
        const allowedTypes = ["application/pdf"];
        return allowedTypes.includes(file.type);
    };

    const MAX_FILE_SIZE = 2 * 1024 * 1024;

    const validateForm = (roleId, departmentId, billDate, itemDescription, subTotal, cgst, sgst, utgst, igst, oldFiles,
        individualBillDocument) => {

        let errors = {};
        errors.itemDescription = []
        let formIsValid = true;

        // if (vendorId && subsidiaryId === 2) {
        if (subTotal === 0) {
            formIsValid = false;
            errors.subTotal = "Subtotal cannot be zero !";
        }
        if (!cgst && !sgst && !utgst && !igst) {
            formIsValid = false;
            errors.tax = "One of the GST Field is required !";
        }

        // if (vendorId && subsidiaryId === 1) {
        //   if (subTotalUSA === 0) {
        //     formIsValid = false;
        //     errors.subTotalUSA = "Subtotal cannot be zero !";
        //   }
        //   if (!salesTax) {
        //     formIsValid = false;
        //     errors.salesTax = "Tax is required !";
        //   }
        // }

        if (roleId === ADMIN_MANAGER_ID) {
            if (!departmentId) {
                formIsValid = false;
                errors.departmentId = "Select Department";
            }
        }
        if (!billDate) {
            formIsValid = false;
            errors.billDate = "Select Bill Date";
        }
        itemDescription.forEach((item, index) => {
            const lineItemErrors = {};

            if (!item.ItemId) {
                formIsValid = false;
                lineItemErrors.ItemId = "Select Item";
            }
            if (!item.Rate) {
                formIsValid = false;
                lineItemErrors.Rate = "Enter Rate";
            }
            if (item.Rate && item.Rate == 0) {
                formIsValid = false;
                lineItemErrors.Rate = "Rate can't be zero";
            }
            if (!item.Quantity) {
                formIsValid = false;
                lineItemErrors.Quantity = "Enter Quantity";
            }
            if (item.Quantity && item.Quantity == 0) {
                formIsValid = false;
                lineItemErrors.Quantity = "Quantity can't be zero";
            }
            if (Object.keys(lineItemErrors).length > 0) {
                errors.itemDescription[index] = lineItemErrors;
            }
            return lineItemErrors;
        });

        if (errors.itemDescription.length === 0) {
            delete errors.itemDescription;
        }

        if (oldFiles.length === 0) {
            if (individualBillDocument.length === 0) {
                formIsValid = false;
                errors.files = "Please upload a document !";
            }
            else {
                for (const item of individualBillDocument) {
                    if (!isValidFileType(item)) {
                        formIsValid = false;
                        errors.files = "Only PDF is allowed !";
                        break;
                    }
                    if (item.size > MAX_FILE_SIZE) {
                        formIsValid = false;
                        errors.files = "Maximum allowed file size is 2MB !";
                        break;
                    }
                    if (individualBillDocument.length > 5) {
                        formIsValid = false;
                        errors.files = "Only 5 files are allowed !";
                        break;
                    }
                }
            }
        }
        else {
            for (const item of individualBillDocument) {
                if (!isValidFileType(item)) {
                    formIsValid = false;
                    errors.files = "Only PDF is allowed !";
                    break;
                }
                if (item.size > MAX_FILE_SIZE) {
                    formIsValid = false;
                    errors.files = "Maximum allowed file size is 2MB !";
                    break;
                }
                if (individualBillDocument.length + oldFiles.length > 5) {
                    formIsValid = false;
                    errors.files = "Only 5 files are allowed !";
                    break;
                }
            }
        }

        setErrors(errors);
        return formIsValid;
    };

    return { errors, setErrors, validateForm };
};

export default EditIndividuallBillValidation;
