import Accordion from "react-bootstrap/Accordion";
import React, { useState} from "react";
import { Modal } from "react-bootstrap";
import eye from "../../../assets/image/eye.png";
import cross from "../../../assets/image/cross.png";
import { BASE_IMAGE_URL } from "../../../utils/services";
 
const BankAccordion = ({ viewData }) => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
 
  const handleClose = () => {
    setShow1(false);
    setShow2(false);
  };
 
  const handleShow1 = () => {
    handleClose();
    setShow1(true);
  };
 
  const handleShow2 = () => {
    setShow1(false);
    setShow2(true);
  };
 
  return (
    <>
    <div className="bank-accordian-box">
       <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h3>Finance Details </h3>
            </Accordion.Header>
            <div className="">
              <Accordion.Body className="p-0">
                <div className="finance-img-with-fileds-div mt-0">
                  <div className="cstm-fields-finance-view">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-md-4 mb-20">
                        <label htmlFor="ifsccode" className="form-label">
                          Payment Mode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ifsccode"
                          placeholder="Enter ifsc code"
                          value={viewData.PaymentMode}
                          disabled
                        />
                      </div>
                      
                      {viewData.BankInfo &&
                        viewData.BankInfo.map((info, index) => (
                          <div key={index} className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 mb-20">
                            <label htmlFor={info.FieldName} className="form-label">
                              {info.FieldName}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name={info.FieldName}
                              placeholder={`Enter ${info.FieldName}`}
                              value={info.FieldValue}
                              disabled
                            />
                          </div>
                        ))}
                      <div className="col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-md-4  d-flex align-items-center pt-2">
                        <span className="cstm-view-bankinf0-btn">
                        <button className="btn" onClick={handleShow1}>
                          View Document
                        </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
 
      <div>
        <Modal show={show1} onHide={handleClose} size="lg" animation={false} className="modal-wrap"  backdrop="static" keyboard={false}>
          <div className="file-preview-modal-title">
            <div className="svg-size me-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                <path d="M9 7V1.5L14.5 7M2 0C0.89 0 0 0.89 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V6L10 0H2Z" fill="white" />
              </svg>
            </div>
            <h3 className="mb-0">File Details</h3>
          </div>
          <div className="file-preview-modal-body">
            <>
              <div className="files-card">
                <div className="d-flex align-items-center">
                  <p>{1}.</p><p className="ms-3">{viewData?.CancelChequeName}</p>
                </div>
                <div className="d-flex align-items-center" onClick={handleShow2}>
                  <span className="cursor-pointer">
                    <img src={eye} alt="" />
                  </span>
                </div>
              </div>
            </>
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-end mb-20">
            <div className="cstm-dngr-btn me-4">
              <button type="button" onClick={handleClose} className="btn">Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
 
      <Modal show={show2} onHide={handleClose} size="lg" animation={false} className="modal-wrap custom-modal-backdrop"  backdrop="static" keyboard={false}>
        <div className="file-preview-modal-body">
          <div className="">
            <span className="cross" onClick={handleClose}>
              <img src={cross} alt="" />
            </span>
            <div className="view-logo">
              <img src={`${BASE_IMAGE_URL}/${viewData?.CancelChequePath}`} alt="" className="imageStyle" />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
 
export default BankAccordion;
 