import  { useState } from 'react';

const EditItemValidation = ({ itemsValue, setErrors }) => {
  const [errors, setValidationErrors] = useState({
    Itemname: '',
    Externalid: '',
    Descriptions: '',
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate Item Name
    if (!itemsValue.Itemname.trim()) {
      newErrors.Itemname = ' Enter Item Name ';
      isValid = false;
    } else {
      newErrors.Itemname = '';
    }

    // Validate External ID
    if (!itemsValue.Externalid.trim()) {
      newErrors.Externalid = 'Enter External Id';
      isValid = false;
    } else {
      newErrors.Externalid = '';
    }

    // Validate Description
    if (!itemsValue.Descriptions.trim()) {
      newErrors.Descriptions = 'Enter Description';
      isValid = false;
    } else {
      newErrors.Descriptions = '';
    }

    setValidationErrors(newErrors);
    setErrors(newErrors); // Pass errors to the parent component
    return isValid;
  };

  return { validateForm };
};

export default EditItemValidation;
