import React, { useContext, useState } from 'react'
import toast from 'react-hot-toast';
import { ModalContext } from '../../utils/Context';
import { postApi } from '../../utils/services';
import { VENDOR_DETAILS_REJECT } from '../../utils/apiRoutes';

const RejectVendor = ({ data, handleVendorList }) => {
  const [disable, setDisable] = useState(false)
  const [rejectReason, setRejectReason] = useState(""); // Add state for reject reason
  const [error, setError] = useState(""); // Add state for error
  const modalContext = useContext(ModalContext);
  const { closeModal } = modalContext;

  const handleReject = async () => {
    try {
      if (!rejectReason) {
        setError("Please provide a rejection reason !");
        return;
      }

      setDisable(true);
      const result = await postApi(VENDOR_DETAILS_REJECT, {
        "vendorId": data.id,
        "rejectionReason": rejectReason,
        "approvalType": data.bankInfoFlag ? "bankDetail" : "personalDetail"
      })

      handleVendorList()
      closeModal();
      toast.success(result.res.message)
    } catch (error) {

    }
  };

  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value);
    setError("");
  };

  return (
    <div className='cstm-frm-mdl'>
      <div class="modal-header">
        <h4 class="modal-title">Rejection Reason</h4>
      </div>
      <div class="modal-body">
        <h6>Are you sure reject this Vendor?</h6>
        <div class="form-floating">
          <textarea value={rejectReason} className="form-control reject-modal-text-area" placeholder="Leave a comment here" id="floatingTextarea" rows={20} maxLength={100} onChange={handleChangeRejectReason}></textarea>
          <label for="floatingTextarea">Reason</label>
        </div>
        {error && <p className="validation-bill-err">{error}</p>}
      </div>
      <div>
      </div>
      <div class="modal-footer">
        <div className=" d-flex align-items-center mb-20">
          <div className="cstm-dngr-btn">
            <button type="button" className="btn" onClick={() => closeModal()}>Cancel</button>
          </div>
          <div className="cstm-reject-btn ms-2">
            <button type="submit" className="btn" onClick={handleReject} disabled={disable}>
              Reject
            </button>
          </div>
        </div>
      </div>

    </div>


  )
}

export default RejectVendor
