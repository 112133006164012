import React, { useEffect, useState } from "react";
import BackButton from "../../../assets/image/BackButton";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { uploadFile } from "../../../utils/helpers/useFileUpload";
import Swal from "sweetalert2";
import { ADMIN_MANAGER_ID, VENDOR_APPROVE } from "../../../utils/constant";
import { getApi, postApi } from "../../../utils/services";
import {
  CREATE_INDIVIDUAL_BILL,
  DEPARTMENT_LIST,
  VENDOR,
  VENDOR_ITEMS,
  VENDOR_TERMS_SUBSIDIARY,
} from "../../../utils/apiRoutes";
import { Helmet } from "react-helmet";
import moment from "moment";
import DeleteIcon from "../../../assets/image/DeleteIcon";
import { useTransactionContext } from "../../../utils/helpers/useTransaction";
import AddIndividuallBillValidation from "../../../middleware/addIndividuallBillValidation";

const IndividualBill = () => {

  const [formState, setFormState] = useState({
    vendorId: '',
    refNo: '',
    billDate: '',
    paymentTypeId: '',
    departmentId: ''
  })

  const [itemDescription, setItemDescription] = useState([{
    itemId: '',
    rate: '',
    quantity: '',
    totalAmount: 0
  }])

  const [tax, setTax] = useState({
    cgst: '',
    sgst: '',
    utgst: '',
    igst: ''
  })

  const navigate = useNavigate();
  const transactionList = useTransactionContext();
  const user = JSON.parse(localStorage.getItem("userData"));
  const [vendorList, setVendorList] = useState([]);
  const [vendorName, setVendorName] = useState('')
  const [departmentList, setDepartmentList] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [subsidiaryId, setSubsidiaryId] = useState('');
  const [termCount, setTermCount] = useState('');
  const [vendorItems, setVendorItems] = useState([]);
  // const [lineLevelGst, setLineLevelGst] = useState(false);
  const [salesTax, setSalesTax] = useState('');
  const [subTotal, setSubTotal] = useState(0);
  const [gstTotal, setGstTotal] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)
  const [subTotalUSA, setSubTotalUSA] = useState(0);
  const [vatTotalUSA, setVATTotalUSA] = useState(0)
  const [grandTotalUSA, setGrandTotalUSA] = useState(0)
  const [attachDocuments, setAttachDocuments] = useState(false)
  const [individualBillDocument, setIndividualBillDocument] = useState([])
  const [loader, setLoader] = useState(false)
  const { errors, setErrors, validateForm } = AddIndividuallBillValidation();

  const minBillDate = moment().subtract(30, 'days')

  const handleList = async () => {
    try {

      let vendorResult = await getApi(VENDOR);
      vendorResult = vendorResult.res.vendor_list.filter(i => i.Status === VENDOR_APPROVE && i.bankInfoFlag === 1);
      setVendorList(vendorResult)

      let departmentResult = await getApi(DEPARTMENT_LIST)
      setDepartmentList(departmentResult.res.department_list)

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleList()
  }, [])

  const fetchData = async () => {
    try {
      if (formState.vendorId) {

        const result = await getApi(`${VENDOR_TERMS_SUBSIDIARY}/${formState.vendorId}`);
        setCurrencySymbol(result.res.terms_subsidiary.Symbol)
        setSubsidiaryId(result.res.terms_subsidiary.SubsidiaryId)
        const termCount = result.res.terms_subsidiary.Term.replace(/\D/g, "");
        setTermCount(termCount)
        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + parseInt(termCount));
        setDueDate(dueDate.toISOString().split("T")[0]);

        const vendorItemsResult = await getApi(`${VENDOR_ITEMS}/${formState.vendorId}`);
        setVendorItems(vendorItemsResult.res.itemsList)
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [formState.vendorId])

  const handleChange = (e) => {
    const { name, value } = e.target

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });

    setFormState({
      ...formState,
      [name]: value,
    })

    if (name === 'vendorId') {
      if (value) {
        let vendorData = vendorList.find((i) => i.id == value);
        const words = vendorData.LegalName.split(" ");
        const vendorName = words[0].trim();
        const vendorNameLowerCase = vendorName.toLowerCase();
        setVendorName(vendorNameLowerCase);
      }
    }

    if (name === 'billDate') {
      const date = value;
      const updatedDueDate = new Date(date);
      updatedDueDate.setDate(updatedDueDate.getDate() + parseInt(termCount));
      setDueDate(updatedDueDate.toISOString().split("T")[0]);
    }

  };

  const handleItemDescriptionChange = (e, index) => {
    const { name, value } = e.target;

    let newValue;
    if (name === "rate") {
      newValue = value.replace(/[^0-9.]/g, "");
      const dotindex = newValue.indexOf(".");
      if (dotindex !== -1 && newValue.length - dotindex > 3) {
        newValue = newValue.slice(0, dotindex + 3);
      }
    }
    else if (name === "quantity") {
      newValue = value.replace(/[^0-9]/g, "");
    } else {
      newValue = value;
    }

    const updatedItemDescription = [...itemDescription];
    updatedItemDescription[index][name] = newValue;

    const rate = updatedItemDescription[index].rate;
    const quantity = updatedItemDescription[index].quantity;

    const totalAmount = (rate * quantity)
    updatedItemDescription[index].totalAmount = totalAmount;

    setItemDescription(updatedItemDescription)

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription[index]) {
      delete updatedErrors.itemDescription[index][name];
    }

    setErrors(updatedErrors);
  };

  const handleAdd = () => {

    setItemDescription([...itemDescription, {
      itemId: '',
      rate: '',
      quantity: '',
      totalAmount: 0
    }])

  };

  const handleRemove = (index) => {

    if (itemDescription.length === 1) {
      alert("Atleast one item should be there");
      return;
    }

    const updatedItemDescription = [...itemDescription];
    updatedItemDescription.splice(index, 1);

    setItemDescription(updatedItemDescription)

    const updatedErrors = { ...errors };
    if (updatedErrors.itemDescription && updatedErrors.itemDescription.length > index) {
      updatedErrors.itemDescription.splice(index, 1);
    }

    setErrors(updatedErrors);
  };

  useEffect(() => {
    const calculateTotal = () => {

      if (subsidiaryId === 2) {

        const subTotal = itemDescription.reduce((acc, item) => acc + item.totalAmount, 0);
        setSubTotal(subTotal);
        setErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.subTotal;
          return updatedErrors;
        });

        const cgst = parseFloat(tax.cgst) ? parseFloat(tax.cgst) : 0
        const sgst = parseFloat(tax.sgst) ? parseFloat(tax.sgst) : 0
        const utgst = parseFloat(tax.utgst) ? parseFloat(tax.utgst) : 0
        const igst = parseFloat(tax.igst) ? parseFloat(tax.igst) : 0

        const gstTotal = subTotal * ((cgst + sgst + utgst + igst) / 100)
        const grandTotal = subTotal + gstTotal
        setGstTotal(gstTotal)
        setGrandTotal(grandTotal)
      }

      if (subsidiaryId === 1) {

        const subTotal = itemDescription.reduce((acc, item) => acc + item.totalAmount, 0);
        setSubTotalUSA(subTotal);
        setErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors.subTotalUSA;
          return updatedErrors;
        });

        const updatedsalesTax = parseFloat(salesTax) ? parseFloat(salesTax) : 0

        const vatTotal = subTotal * ((updatedsalesTax) / 100)
        const grandTotal = subTotal + vatTotal
        setVATTotalUSA(vatTotal)
        setGrandTotalUSA(grandTotal)
      }

    }
    calculateTotal()
  }, [itemDescription, tax, salesTax, subsidiaryId])

  const handleTax = (e) => {
    const { name, value } = e.target

    const isValid = value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,3})(\.\d{0,2})?$/.test(value);

    if (isValid && hasValidDecimal) {
      setTax({
        ...tax,
        [name]: value,
      })
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors.tax
      return updatedErrors
    })
  }

  const handleSalesTaxChange = (e) => {
    const { value } = e.target

    const isValid = value >= 0 && value <= 100;
    const hasValidDecimal = /^(\d{0,3})(\.\d{0,2})?$/.test(value);

    if (isValid && hasValidDecimal) {
      setSalesTax(value)
    }

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors }
      delete updatedErrors.salesTax
      return updatedErrors
    })
  }

  const handleFileInputChange = (e) => {
    const individualBillDocument = Array.from(e.target.files);
    setIndividualBillDocument(individualBillDocument);

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.files;
      return updatedErrors;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const isValid = validateForm(formState.vendorId, formState.billDate, itemDescription, subsidiaryId, subTotal, tax, subTotalUSA, user.RoleId, formState.departmentId, individualBillDocument, salesTax);

      if (isValid) {

        setLoader(true);

        let individualBillDocumentArray = [];

        const updatedItemDescription = itemDescription.map((row) => ({
          itemId: Number(row.itemId),
          rate: Number(row.rate),
          quantity: Number(row.quantity),
          totalAmount: row.totalAmount
        }));

        const fileUploadPromises = individualBillDocument.map(async (file) => {
          const result = await uploadFile(
            "individualbills",
            vendorName,
            formState.vendorId,
            file
          );
          const singlefile = {
            FileName: result.fileName,
            FilePath: result.filePath,
          };
          individualBillDocumentArray.push(singlefile);
        });
        await Promise.all(fileUploadPromises);

        const result = await postApi(CREATE_INDIVIDUAL_BILL, {
          vendorId: Number(formState.vendorId),
          transactionId: transactionList[4].id,
          roleId: user.RoleId,
          referenceNo: formState.refNo,
          departmentId: formState.departmentId ? Number(formState.departmentId) : null,
          billDate: formState.billDate,
          dueDate: dueDate,
          paymentTypeId: 2,
          isGstInclude: false,
          isLineLevelGst: false,
          itemDescription: updatedItemDescription,
          individualBillDocument: individualBillDocumentArray,
          cgst: tax.cgst ? Number(tax.cgst) : null,
          sgst: tax.sgst ? Number(tax.sgst) : null,
          utgst: tax.utgst ? Number(tax.utgst) : null,
          igst: tax.igst ? Number(tax.igst) : null,
          salesTax: salesTax ? Number(salesTax) : null,
          individualBillTotalAmount: subsidiaryId === 2 ? subTotal : subTotalUSA,
          gstTotal: subsidiaryId === 2 ? gstTotal : vatTotalUSA,
          grandTotal: subsidiaryId === 2 ? grandTotal : grandTotalUSA
        });
        if (result.res.success) {
          Swal.fire({
            position: "center-center",
            icon: "success",
            title: result.res.message,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/individual-bill-list");
        }
      }
    }
    catch (error) {
      setLoader(false);
    };
  }

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Standalone Bill</title>
      </Helmet>

      <div className="container-section">
        <div className="section-header">
          <button onClick={() => navigate(-1)} className="back-viewbtn me-2">
            <div className="svgsize">
              <BackButton />
            </div>
          </button>
          <h3>Standalone Bill</h3>
        </div>

        <form>

          <div className="form-style">
            <div className="row">

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="vendor" className="form-label">
                  Vendor<span className="required">*</span>
                </label>

                <select id="vendorId" className="form-select" name="vendorId" value={formState.vendorId}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {vendorList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.LegalName}
                    </option>
                  ))}
                </select>
                {errors.vendorId && (
                  <span className="cstm-err">{errors.vendorId}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="refNo" className="form-label">
                  Reference No<span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter reference number"
                  name="refNo"
                  value={formState.refNo}
                  onChange={handleChange}
                />
                {/* {errors.reference && (
                  <p className="validation-bill-err">{errors.reference}</p>
                )} */}
              </div>

              {user.RoleId === ADMIN_MANAGER_ID && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                  <label htmlFor="departmentId" className="form-label">
                    Department<span className="required">*</span>
                  </label>
                  <select name="departmentId" id="departmentId" className="form-select" value={formState.departmentId} onChange={handleChange}>
                    <option value="">Select</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.DepartmentId}>
                        {item.DepartmentName}
                      </option>
                    ))}
                  </select>
                  {errors.departmentId && <span className="cstm-err">{errors.departmentId}</span>}
                </div>

              )}

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="billDate" className="form-label">
                  Bill Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  min={minBillDate.format('YYYY-MM-DD')}
                  className="form-control"
                  value={formState.billDate}
                  name="billDate"
                  onChange={handleChange}
                  onKeyDown={(e) => e.preventDefault()}
                />
                {errors.billDate && (
                  <span className="cstm-err">{errors.billDate}</span>
                )}
              </div>

              <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="dueDate" className="form-label">
                  Due Date
                </label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  className="form-control"
                  value={dueDate}
                  disabled
                />
                {/* {errors.dueDate && (
                  <span className="cstm-err">{errors.dueDate}</span>
                )} */}
              </div>

              {/* <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="paymentTypeId" className="form-label">
                  Payment Type<span className="required">*</span>
                </label>
                <select
                  className="form-select"
                  name="paymentTypeId"
                  value={formState.paymentTypeId}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="1">Cash</option>
                  <option value="2">Visa Card</option>
                  <option value="3">UPI</option>
                  <option value="4">Cheque</option>
                </select>
              
              </div> */}

              {/* <div className="col-xl-3 col-lg-4 col-md-3 mb-20 position-relative">
                <label htmlFor="paymentId" className="form-label">
                  Payment Id<span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter payment Id"
                  name="paymentId"
                  value={formState.paymentId}
                  onChange={handleChange}
                />
              </div> */}

              {/* {formState.vendorId && subsidiaryId === 2 && (

                <div className="col-xl-3 col-lg-4 col-md-3 mb-20">
                  <div className="d-flex algin-item-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={lineLevelGst}
                      onChange={(e) => setLineLevelGst(e.target.checked)}
                    />
                    <p className="ms-1">Line Level GST</p>
                  </div>
                </div>

              )} */}

            </div>
          </div>

          <div className="form-style">
            <div className="">
              <div className="section-sub-heading">
                <h3>Item Description</h3>
              </div>
              <div className="pur-item-list table-responsive tbl-body-shadow">
                <table className="w-100 table table-theme-1 mb-0">
                  <thead className="table-heads">
                    <tr>
                      <th className="text-center">Item</th>
                      <th className="text-center">Rate</th>
                      <th className="text-center">Quantity</th>
                      {/* {subsidiaryId === 2 && lineLevelGst && (
                        <>
                          <th className="text-center">CGST%</th>
                          <th className="text-center">SGST%</th>
                          <th className="text-center">UTGST%</th>
                          <th className="text-center">IGST%</th>
                          <th className="text-center">GST Amount</th>
                        </>
                      )} */}
                      <th className="text-center">Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {itemDescription.map((item, index) => (
                      <tr key={index}>
                        <td className="custom-width">
                          <div className="position-relative">
                            <select className="form-select mb-20" name="itemId"
                              value={item.itemId}
                              onChange={(e) => handleItemDescriptionChange(e, index)}
                            >
                              <option value="">Select</option>
                              {formState.vendorId && vendorItems.map((item, index) => (
                                <option key={index} value={item.itemId}>
                                  {item.itemname}
                                </option>
                              ))}
                            </select>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].itemId && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].itemId}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="rate"
                              autocomplete="off"
                              placeholder="Rate"
                              value={item.rate}
                              onChange={(e) => handleItemDescriptionChange(e, index)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].rate && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].rate}
                                </span>
                              )}
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" name="quantity"
                              autocomplete="off"
                              placeholder="Quantity"
                              value={item.quantity}
                              onChange={(e) => handleItemDescriptionChange(e, index)}
                            >
                            </input>
                            {errors.itemDescription &&
                              errors.itemDescription[index] &&
                              errors.itemDescription[index].quantity && (
                                <span className="cstm-err">
                                  {errors.itemDescription[index].quantity}
                                </span>
                              )}
                          </div>
                        </td>
                        {/* {subsidiaryId === 2 && lineLevelGst && (
                          <>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="cgst"
                                  autocomplete="off"
                                  placeholder="CGST%"
                                  value={item.cgst}
                                // onChange={(e) => handleItemDescriptionChange(e, index)}
                                >
                                </input>
                             
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="sgst"
                                  autocomplete="off"
                                  placeholder="SGST%"
                                  value={item.sgst}
                                // onChange={(e) => handleItemDescriptionChange(e, index)}
                                >
                                </input>
                             
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="utgst"
                                  autocomplete="off"
                                  placeholder="UTGST%"
                                  value={item.utgst}
                                // onChange={(e) => handleItemDescriptionChange(e, index)}
                                >
                                </input>
                            
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="igst"
                                  autocomplete="off"
                                  placeholder="IGST%"
                                  value={item.igst}
                                // onChange={(e) => handleItemDescriptionChange(e, index)}
                                >
                                </input>
                           
                              </div>
                            </td>
                            <td className="custom-width">
                              <div className="position-relative">
                                <input type="text" className="form-control text-end mb-20" name="gstAmount"
                                  autocomplete="off"
                                  placeholder="GST Amount"
                                  value={item.gstAmount}
                                  disabled
                                >
                                </input>
                           
                              </div>
                            </td>
                          </>
                        )} */}
                        <td className="custom-width">
                          <div className="position-relative">
                            <input type="text" className="form-control text-end mb-20" placeholder="Amount" name="totalAmount"
                              value={item.totalAmount.toFixed(2)}
                              disabled
                            >
                            </input>
                          </div>
                        </td>
                        <td className="custom-width">
                          <div className="cursor-pointer d-flex align-items-center justify-content-center mb-20">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip"
                                  id="tooltips1"
                                >
                                  Delete
                                </Tooltip>
                              }
                            ><span
                              className=""
                              onClick={(e) => handleRemove(index)}
                            >
                                <DeleteIcon />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Addmoreicon">
              <button type="button" className="addIcon" onClick={handleAdd}>
                <p>+</p>
              </button>
            </div>

            {formState.vendorId && subsidiaryId === 2 && (

              <div className="d-flex justify-content-end inv-gst-tbl-cont mt-4">
                <div className="cstm-width">

                  <div className="d-flex align-items-center mb-4">
                    <span className="gst-cont-label">Sub Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${subTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                      {errors.subTotal && (
                        <div className="validation-bill-err">
                          {errors.subTotal}
                        </div>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">CGST%</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.cgst}
                        name="cgst"
                        className="form-control text-end"
                        placeholder="Enter CGST%"
                        onChange={handleTax}
                        disabled={tax.utgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">SGST%</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.sgst}
                        name="sgst"
                        className="form-control text-end"
                        placeholder="Enter SGST%"
                        onChange={handleTax}
                        disabled={tax.utgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">UTGST%</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.utgst}
                        name="utgst"
                        className="form-control text-end "
                        placeholder="Enter UTGST%"
                        onChange={handleTax}
                        disabled={tax.cgst || tax.sgst || tax.igst}
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <span className="gst-cont-label">IGST%</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={tax.igst}
                        name="igst"
                        className="form-control text-end"
                        placeholder="Enter IGST%"
                        onChange={handleTax}
                        disabled={tax.cgst || tax.sgst || tax.utgst}
                      />
                      {errors.tax && (
                        <p className="validation-bill-err">
                          {errors.tax}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">GST Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${gstTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">Grand Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${grandTotal.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                </div>
              </div>

            )}

            {formState.vendorId && subsidiaryId === 1 && (

              <div className="d-flex justify-content-end inv-gst-tbl-cont mt-4">
                <div className="cstm-width">

                  <div className="d-flex align-items-center mb-4">
                    <span className="gst-cont-label">Sub Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${subTotalUSA.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                      {errors.subTotalUSA && (
                        <p className="validation-bill-err">
                          {errors.subTotalUSA}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-4">
                    <span className="gst-cont-label">Tax(%)</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={salesTax}
                        name="salesTax"
                        className="form-control text-end"
                        placeholder="Enter Tax(%)"
                        onChange={handleSalesTaxChange}
                      />
                      {errors.salesTax && (
                        <p className="validation-bill-err">
                          {errors.salesTax}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">VAT Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${vatTotalUSA.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-2">
                    <span className="gst-cont-label">Grand Total</span>
                    <span className="gst-cont-semicolumn">:</span>
                    <span className="gst-cont-input">
                      <input
                        type="text"
                        value={`${currencySymbol} ${grandTotalUSA.toFixed(2)}`}
                        className="form-control text-end"
                        disabled
                      />
                    </span>
                  </div>

                </div>
              </div>

            )}

            <div className="cstm-checkbox mb-2">
              <div className="cstm-checkbox-inner d-flex align-items-center">
                <input
                  className="form-check-input m-0"
                  type="checkbox"
                  checked={attachDocuments}
                  onChange={(e) => setAttachDocuments(e.target.checked)}
                />
                <div className="cstm-checkbox-inr-lbl-field d-flex align-items-center mt-2">
                  <label htmlFor="" className="ms-2">
                    Check for relevant document
                  </label>
                  <span className="required">*</span>
                  <span className=" infoIcon"></span>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-xl-3 col-lg-4 col-md-3 position-relative">
                <div className="input-group mmm">
                  <input
                    type="file"
                    id="files"
                    className="form-control"
                    name="files"
                    disabled={!attachDocuments}
                    onChange={handleFileInputChange}
                    multiple
                  />
                </div>
                {errors.files && (
                  <p className="validation-bill-err">{errors.files}</p>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-20">

              <div className="cstm-success-btn ms-2">
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => handleSubmit(e)}
                >
                  {loader ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>

            </div>

          </div>

        </form >
      </div >
    </>
  );
};

export default IndividualBill;
