export const VENDOR = "Vendor";
export const STAFF = "Staff";
export const FINANCE = "Finance";

// ---------------------Role ID start---------------------------------------------------------//

export const SUPER_ADMIN_ID = 1;
export const VP_ID = 2;
export const ADMIN_EXECUTIVE_ID = 3;
export const ADMIN_MANAGER_ID = 4;
export const VENDORID = 5;
export const FINANCE_ID = 6;

// ---------------------Role ID end---------------------------------------------------------//
// ---------------------Status ID start---------------------------------------------------------//

export const VENDOR_APPROVE = 2;
export const VENDOR_VP_PENDING = 4;
export const VENDOR_MANAGER_PENDING = 1;
export const VENDOR_REJECT = 3;
export const FINANCE_PENDING = 5;
export const PAYMENT_PENDING = 6;
export const FULLY_PAID = 8;

// ---------------------Status ID start---------------------------------------------------------//

// ---------------------all validation constant start -----------------------------------------------------//
export const PASSWORDVALIDATOR = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/;
export const EMAILVALIDATOR = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// ---------------------all validation constant end -----------------------------------------------------//

export const CONTRACT_SITE_CONFIGERATION_ID = 1;
