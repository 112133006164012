import React, { useState, useEffect } from "react";
import BackButton from "../../../assets/image/BackButton";
import { getApi, postApi } from "../../../utils/services";
import { ITEM_LIST, ADD_ITEM, VENDOR } from "../../../utils/apiRoutes";
import EditItemModal from "../../modal/EditItemModal";
import edit from "../../../assets/image/edit2.png";
import { toast } from "react-hot-toast";
import AddItemValidation from "../../../middleware/addItemValidation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import avatar from "../../../assets/image/Assign.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MapItemModal from "../../modal/MapItemModal";

const AddItem = () => {
  const [itemListData, setItemListData] = useState([]);

  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);

  //for assign vendor name with item  name
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  //for Edit vendor name with assign item name
  const [showEditMapModal, setShowEditMapModal] = useState(false);
  const [selectedMapItem, setSelectedMapItem] = useState({});

  /* for add modal map */
  const handleEdit = (item, e) => {
    e.preventDefault();
    setSelectedItem(item);
    setShowEditModal(true);
  };

  /* for Edit modal map */
  const handleMapitem = (item, e) => {
    e.preventDefault();
    setSelectedMapItem(item);
    setShowEditMapModal(true);
  };

  /* item list */
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await getApi(ITEM_LIST);
      setItemListData([...result.res.item_list]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  /* ---end---- */

  const [rows, setRows] = useState([
    {
      itemname: "",
      externalid: "",
      description: "",
      checked: false,
    },
  ]);
  const { errors, setErrors, validate } = AddItemValidation({ rows });

  const handleAdd = () => {
    if (rows.length >= 0) {
      setRows([
        ...rows,
        {
          itemname: "",
          externalid: "",
          description: "",
          checked: true,
        },
      ]);
    }
  };

  const handleContactChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "itemname") {
      const newVal = value.replace(
        /[^a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g,
        ""
      );
      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;
      setRows(updatedRows);
    } else if (name === "externalid") {
      const newVal = value.replace(/[^0-9]/g, "");
      const updatedRows = [...rows];
      updatedRows[index][name] = newVal;
      setRows(updatedRows);
    } else if (name === "description") {
      const updatedRows = [...rows];
      updatedRows[index][name] = value;
      setRows(updatedRows);
    }
    const updatedErrors = { ...errors };
    if (updatedErrors.rows && updatedErrors.rows[index]) {
      delete updatedErrors.rows[index][name];
    }
    setErrors(updatedErrors);
  };

  const handleRemove = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
    const updatedErrors = { ...errors };
    if (updatedErrors.rows && updatedErrors.rows.length > index) {
      updatedErrors.rows.splice(index, 1);
    }
    setErrors(updatedErrors);
  };

  /* for submit all fields */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        setDisable(true);
        setLoader(true);

        const payload = {
          items: rows.map((item) => ({
            itemName: item.itemname,
            description: item.description,
            externalId: parseInt(item.externalid),
          })),
        };

        const result = await postApi(ADD_ITEM, payload);

        if (result.res.success) {
          setRows([
            {
              itemname: "",
              externalid: "",
              description: "",
            },
          ]);
          setDisable(false);
          setLoader(false);
          fetchData();
          toast.success(result.res.message);
        }

        if (!result.res.success) {
          setDisable(false);
          setLoader(false);

          if (result.res.itemIndexes && result.res.itemIndexes.length > 0) {
            result.res.itemIndexes.forEach((index) => {
              setErrors((prevErrors) => ({
                ...prevErrors,
                rows: {
                  ...prevErrors.rows,
                  [index]: { itemname: result.res.message },
                },
              }));
            });
          }

          if (
            result.res.externalIdIndexes &&
            result.res.externalIdIndexes.length > 0
          ) {
            result.res.externalIdIndexes.forEach((index) => {
              setErrors((prevErrors) => ({
                ...prevErrors,
                rows: {
                  ...prevErrors.rows,
                  [index]: { externalid: result.res.message },
                },
              }));
            });
          }
        }
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("An error occurred while submitting data.");
    }
  };
  /*---end---*/

  const handleEditSuccess = () => {
    fetchData();
  };

  const handleEditMapItemSuccess = () => {
    fetchData();
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemListData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (
      pageNumber < 1 ||
      pageNumber > Math.ceil(itemListData.length / itemsPerPage)
    ) {
      return; 
    }
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>Vendor Portal || Item</title>
      </Helmet>
      <div className="container-section">
        <div className="section-header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <button
              onClick={() => navigate(-1)}
              className="back-viewbtn me-2"
              type="button"
            >
              <div className="svgsize">
                <BackButton />
              </div>
            </button>
            <h3>Add Item</h3>
          </div>
          <div>
            <button type="button" className="cstm-add-btn" onClick={handleAdd}>
              Add More
            </button>
          </div>
        </div>
        <div className="form-style">
          {rows.map((row, index) => (
            <div
              className={` mb-4 ${
                row.checked ? `row mainDiv` : `row optionalDiv`
              } `}
              key={index}
              style={{ margin: row.checked ? "0" : "0" }}
            >
              {row.checked && (
                <span
                  className="remove-icon"
                  onClick={() => handleRemove(index)}
                >
                  -
                </span>
              )}
              <div className=" col-lg-4 col-md-12 mb-20 position-relative">
                <div className="form-group">
                  <label htmlFor="itemname" className="form-label">
                    Item Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="itemname"
                    className="form-control"
                    placeholder="Enter item name"
                    name="itemname"
                    value={row.itemname}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].itemname && (
                      <span className="validate_error-div">
                        {errors.rows[index].itemname}
                      </span>
                    )}
                </div>
              </div>
              <div className=" col-lg-4 col-md-12 mb-20 position-relative">
                <div className="form-group">
                  <label htmlFor="externalid" className="form-label">
                    External System Id<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="externalid"
                    className="form-control"
                    placeholder="Enter external Id"
                    name="externalid"
                    value={row.externalid}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].externalid && (
                      <span className="validate_error-div">
                        {errors.rows[index].externalid}
                      </span>
                    )}
                  {errors.itemNameDuplicate && (
                    <span className="validate_error-div">
                      {errors.itemNameDuplicate}
                    </span>
                  )}
                </div>
              </div>
              <div className=" col-lg-4 col-md-12 mb-20 position-relative">
                <div className="form-group">
                  <label htmlFor="description" className="form-label">
                    Description<span className="required">*</span>
                  </label>
                  <textarea
                    id="description"
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={row.description}
                    maxLength={100}
                    onChange={(e) => handleContactChange(index, e)}
                  />
                  {errors.rows &&
                    errors.rows[index] &&
                    errors.rows[index].description && (
                      <span className="validate_error-div">
                        {errors.rows[index].description}
                      </span>
                    )}
                </div>
              </div>
            </div>
          ))}

          <div className=" d-flex align-items-center justify-content-end mb-20">
            <div className="cstm-success-btn ms-2">
              <button
                type="submit"
                className="btn"
                onClick={(e) => handleSubmit(e)}
                disabled={disable}
              >
                {" "}
                {loader ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>

        {/* for  add vendor name modal map */}
        {showEditModal && (
          <EditItemModal
            handleClose={() => setShowEditModal(false)}
            show={showEditModal}
            selectedItem={selectedItem}
            itemListData={itemListData}
            onEditSuccess={handleEditSuccess}
          />
        )}

        {/* //for  Edit vendor name modal map */}
        {showEditMapModal && (
          <MapItemModal
            handleClose={() => setShowEditMapModal(false)}
            show={showEditMapModal}
            selectedMapItem={selectedMapItem}
            itemListData={itemListData}
            onEditSuccess={handleEditMapItemSuccess}
          />
        )}

        <div className="section-header">
          <h3>Item Listing</h3>
        </div>
        <div className="form-style">
          <div className="table-responsive tbl-body-shadow">
            <table className="w-100 table-theme-1">
              <thead>
                <tr>
                  <th className="text-right">#</th>
                  <th className="text-left">Item</th>
                  <th className="text-left">Vendor</th>
                  <th className="text-end">External System Id</th>
                  <th className="text-left">Description</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                    <td>{row?.itemName}</td>
                    <td className="">{row?.vendorNames.join(",")}</td>
                    <td className="pe-4 cstm-right-align-value">
                      {row?.ExternalId}
                    </td>
                    <td className="text-left description-width">
                      {row?.Description}
                    </td>
                    <td className="text-end">
                      <div className="d-flex align-items-center cursor-pointer">
                      <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip cstm-tooltip"
                                  id="tooltips1"
                                >
                                  Assign
                                </Tooltip>
                              }
                            >
                        <img
                          src={avatar}
                          alt=""
                          onClick={(e) => handleMapitem(row, e)}
                        />
                        </OverlayTrigger>
                        <>
                          <div className="cursor-pointer d-flex align-items-center justity-content-center">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  className="custom-tooltip cstm-tooltip"
                                  id="tooltips1"
                                >
                                  Edit
                                </Tooltip>
                              }
                            >
                              <div
                                className="ms-1 btn-vendors"
                                onClick={(e) => handleEdit(row, e)}
                              >
                                <img src={edit} alt="" />
                              </div>
                            </OverlayTrigger>
                          </div>
                        </>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
          <ul className="pagination">
            <li className="page-item" style={{border:"transparent"}} >
              <button
                onClick={() => paginate(currentPage - 1)}
                className="page-link"
                style={{background:"#e9ecef",color:"#222"}}
              >
                &lt; 
              </button>
            </li>
            {Array.from({
              length: Math.ceil(itemListData.length / itemsPerPage),
            }).map((_, index) => (
              <li key={index} className="page-item " style={{border:"transparent"}} >
                <button
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                  style={{background:"#0dcaf0",color:"#fff"}}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item" style={{border:"transparent"}} >
              <button
                onClick={() => paginate(currentPage + 1)}
                className="page-link"
                style={{background:"#e9ecef",color:"#222"}}
              >
                &gt; 
              </button>
            </li>
          </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddItem;
